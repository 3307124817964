/* eslint-disable react/prop-types */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import vehiclePlaceholder from "../../../assets/vehicles/placeholder.png";
import vehiclesConstant from "../../../helpers/vehiclesConstant";
import {
  actions,
  setBooking,
  setBookingProperty,
} from "../../../redux/reducers/bookingReducer";
import GoogleAddressInput from "../GoogleAddressInput";
import TravelForm from "../TravelForm";

function MainForm({ step, setStep }) {
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles);
  const booking = useSelector((state) => state.booking);

  const [pickupAddress, setPickupAddress] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [pax, setPax] = useState("");

  const today = new Date();

  const handleChangeVehicle = (name) => {
    const selectedVehicle = vehicles.find((v) => v.name === name);

    setVehicleName(name);
    setVehicle(selectedVehicle);
  };

  const handleStartBooking = async (e) => {
    e.preventDefault();

    const newBooking = {
      pickup_address: pickupAddress,
      dropoff_address: dropoffAddress,
      pickup_date: pickupDate,
      pickup_time: pickupTime,
      number_of_passenggers: pax,
      vehicle_type: vehicle,
    };

    dispatch(setBooking(newBooking));
    setStep(step + 1);
  };

  useEffect(() => {
    if (booking) {
      setPickupAddress(booking.pickup_address);
      setDropoffAddress(booking.dropoff_address);
      setPickupDate(booking.pickup_date);
      setPickupTime(booking.pickup_time);
    }
  }, [booking]);

  return (
    <section id="booking-form" className="section-light">
      <div className="container">
        <h2>Reserve Online!</h2>
        <h5>Our drivers speak English and Spanish</h5>

        <div className="container">
          <form className="row" onSubmit={handleStartBooking}>
            <div className="col-12 col-md-8">
              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <GoogleAddressInput
                    addressType="PICKUP"
                    address={pickupAddress}
                    setAddress={setPickupAddress}
                  />
                </div>
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <GoogleAddressInput
                    addressType="DROPOFF"
                    address={dropoffAddress}
                    setAddress={setDropoffAddress}
                  />
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="date"
                      className="form-control"
                      id="floatingPickupDate"
                      placeholder="Pickup Address"
                      min={today.toISOString().split("T")[0]}
                      value={pickupDate}
                      onChange={(e) => setPickupDate(e.target.value)}
                    />
                    <label htmlFor="floatingPickupDate">Pickup Date</label>
                  </div>
                </div>
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="time"
                      className="form-control"
                      id="floatingPickupTime"
                      placeholder="Pickup Time"
                      min="00:00"
                      max="23:59"
                      step="1800"
                      value={pickupTime}
                      onChange={(e) => setPickupTime(e.target.value)}
                    />
                    <label htmlFor="floatingPickupTime">Pickup Time</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingPassengers"
                      placeholder="Passengers"
                      value={pax}
                      onChange={(e) => setPax(e.target.value)}
                    />
                    <label htmlFor="floatingPassengers">Passengers</label>
                  </div>
                </div>
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingVehicle"
                      placeholder="Select a Vehicle"
                      aria-label="Select Vehicle"
                      value={vehicleName}
                      disabled={!pax}
                      onChange={(e) => handleChangeVehicle(e.target.value)}
                    >
                      <option value="" disabled>
                        ----------
                      </option>
                      {vehicles
                        .filter((v) => pax && v.paxMax >= pax)
                        .map((v) => (
                          <option key={v.id} value={v.name}>
                            {v.name}
                          </option>
                        ))}
                    </select>
                    <label htmlFor="floatingVehicle">Select a Vehicle</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <button
                  type="submit"
                  className="btn btn-block btn-danger btn-lg col-12 col-md-6 offset-md-6 my-2 my-md-3"
                  disabled={
                    !pickupAddress ||
                    !dropoffAddress ||
                    !pickupDate ||
                    !pickupTime ||
                    !vehicle ||
                    !pax
                  }
                >
                  Complete Booking
                </button>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={
                  vehicle ? vehiclesConstant[vehicle.id] : vehiclePlaceholder
                }
                alt="Vehicle selected"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default MainForm;
