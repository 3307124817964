import React from "react";

// eslint-disable-next-line react/prop-types
function HeaderSecondary({ pageTitle, pageSubtitle }) {
  return (
    <div className="header-secondary">
      <h1>{pageTitle}</h1>
      <h2>{pageSubtitle}</h2>
    </div>
  );
}

export default HeaderSecondary;
