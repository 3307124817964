import React from "react";

import HeaderSecondary from "../../components/HeaderSecondary";

function OurFleet() {
  return (
    <>
      <HeaderSecondary
        pageTitle="Our Fleet"
        pageSubtitle="We have the Best Brands"
      />
      <section id="main-offers" className="section-white">
        <div className="container">
          Lorem ipsum dolor sit amet, consectetur adip
        </div>
      </section>
    </>
  );
}

export default OurFleet;
