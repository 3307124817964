import React from "react";

import HeaderSecondary from "../../components/HeaderSecondary";
import BestLimoSign from "../../assets/best-limo-one-sign.png";

function Terms() {
  return (
    <>
      <HeaderSecondary
        pageTitle="Terms and Conditions"
        pageSubtitle="For better understanting on both sides"
      />

      <section id="terms-conditions" className="section-white">
        <div className="container text-justified">
          <p>
            Prices vary by date and vehicle availability. (For seasonal events
            such as graduations and weddings, be sure to reserve early).
          </p>
          <p>
            By submitting reservation form, you are giving Bestlimo.NYC, Inc.
            the right to email or contact you regarding our specials and company
            announcements. We will never sell or give your information away to
            anyone. You will always have the right to opt out at anytime.
          </p>
          <p>
            The customer accepts to pay the price specified for the services
            described. The customer agrees to be charged for any aditional
            services.
          </p>
          <p>
            If there&lsquo;s any problem with a vehicle or driver the company
            must be informed within 24 hours. If the company is not informed
            with in 24 hours the complaint will not be validated.
          </p>
          <h2 className="text-centered">Cancellation fees</h2>
          <h5>For Buses, Party Buses, Strech Limousines and Sprinters</h5>
          <ul>
            <li>
              Any cancellation made before one week from pickup time will not be
              charged
            </li>
            <li>
              Any cancellation made lees than a week before pickup time will
              result in a charge of 30% of the reservation cost
            </li>
            <li>
              Any cancellation made 1 to 3 days before pickup time will result
              in a charge of 50% of the reservation cost
            </li>
            <li>
              A no show or a cancellation made the same day of pickup time will
              result in a charge of 100% of the reservation cost
            </li>
          </ul>
          <h5>For Sedans and SUV&lsquo;s</h5>
          <ul>
            <li>
              For any cancellation made with 4 days of anticipation there will
              not be charged
            </li>
            <li>
              For any cancellation made with 3 days of anticipation there will
              be a charge of 30% of the reservation cost
            </li>
            <li>
              For any cancellation made with 2 days of anticipation there will
              be a charge of 50% of the reservation cost
            </li>
            <li>
              For a no show or a cancellation made on the same day of the
              reservation there will be a charge of 100% of the reservation cost
            </li>
          </ul>
          <p>
            All deposits are NON refundable. Company is not liable in the event
            of mechanical breakdown while on hire and will only be responsible
            for making up lost time at a mutually agreed date. The client
            assumes full financial liability for any damage to the vehicle
            caused during the duration of the rental by them or any members of
            their party. Sanitation fee is 250.00.
          </p>
          <p>
            Alcohol Consumption and drug use is prohibited by law. Any fines
            will be paid for by the customer. The driver has the right to
            terminate run without refund (if there is blatant indiscretion on
            the part of the client(s)). Smoking is not permitted in any of our
            vehicles, absolutely no exceptions.
          </p>
          <p>
            Waiting time will apply after the first 15 minutes of prearranged
            time described on the run sheet, At Airports we allow 45 minutes on
            domestic flights and 1 Hour on International flights, after the
            allowed time we will charge wait time. If you can not locate you
            driver please call the office @
            <a href="tel:18775435335" className="text-link">
              +1 (877) 543-5335
            </a>
            . Leaving without contacting the office will result in a no show
            charge. Not responsible for delays or the termination in winter
            caused by unsafe road conditions (ie. not salted, accidents, etc.).
            Not responsible for articles left in the Vehicles. Any Vehicle
            cannot be loaded beyond seating capacity.
          </p>
          <p>
            Point-to-point service is calculated based on mileage and zones.
            This service is not for a set period of time, just the time needed
            to travel from point A to point B. if you need a vehicle for
            &quot;X&quot; hour(s) please reserve the hourly service.
          </p>
          <p>
            After the client is picked up at the agreed time and place, any
            traffic delay or rush hour is not the responsibility of the company.
            We recommend booking with as much time as possible.
          </p>
          <p>
            All cancellations must be made via email, if not the cancellation
            will not be valid, and the customer must pay the 100% of the
            reservation
          </p>
          <p>
            Cancellations are counted by the day not by the hour of reservation.
          </p>
          <br />
          <h5>
            Thank you for choosing us!
            <br />
            We are happy to serve you in the most professional manner!
          </h5>
          <p className="text-centered">
            <img src={BestLimoSign} alt="Best Limo sign" />
          </p>
        </div>
      </section>
    </>
  );
}

export default Terms;
