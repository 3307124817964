/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hours,
  roundTripDiscount,
  serviceTypes,
} from "../../../helpers/bookingConstant";
import { setBooking } from "../../../redux/reducers/bookingReducer";
import GoogleAddressInput from "../GoogleAddressInput";

const TravelForm = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.booking);

  const [pickupAddress, setPickupAddress] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");

  const [serviceType, setServiceType] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [roundTrip, setRoundTrip] = useState(false);
  const [returnDate, setReturnDate] = useState("");
  const [returnTime, setReturnTime] = useState("");

  const handleNextStep = () => {
    const newBooking = {
      ...booking,
      service_type: serviceType,
      service_hours: serviceHours,
      round_trip: roundTrip,
      return_date: returnDate,
      return_time: returnTime,
    };

    dispatch(setBooking(newBooking));
    setStep(step + 1);
  };

  useEffect(() => {
    if (booking) {
      setPickupAddress(booking.pickup_address);
      setDropoffAddress(booking.dropoff_address);
      setPickupDate(booking.pickup_date);
      setPickupTime(booking.pickup_time);
    }
  }, [booking]);

  return (
    <section id="travel-form" className="section-light no-padding-top">
      <div className="container">
        <h2>Travel Information!</h2>
        <h5>Minor details for complete</h5>

        <div className="container  offset-md-2">
          <form className="row">
            <div className="col-12 col-md-8">
              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <GoogleAddressInput
                    addressType="PICKUP"
                    address={pickupAddress}
                    setAddress={setPickupAddress}
                  />
                </div>
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <GoogleAddressInput
                    addressType="DROPOFF"
                    address={dropoffAddress}
                    setAddress={setDropoffAddress}
                  />
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <input
                    type="date"
                    className="form-control py-3"
                    id="floatingPickupDate"
                    placeholder="Pickup Address"
                    value={pickupDate}
                    onChange={(e) => setPickupDate(e.target.value)}
                  />
                </div>
                <div className="col-6 d-inline-block my-2 my-md-3">
                  <input
                    type="time"
                    className="form-control py-3"
                    id="floatingPickupTime"
                    placeholder="Pickup Time"
                    value={pickupTime}
                    onChange={(e) => setPickupTime(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gx-2">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    type="button"
                    className={
                      serviceType === serviceTypes.PICKUP_DROPOFF
                        ? "btn btn-outline-danger col-6 py-3 my-2 my-md-3 active"
                        : "btn btn-outline-danger col-6 py-3 my-2 my-md-3"
                    }
                    onClick={() => setServiceType(serviceTypes.PICKUP_DROPOFF)}
                  >
                    Pick up - Drop off
                  </button>
                  <button
                    type="button"
                    className={
                      serviceType === serviceTypes.HOURS
                        ? "btn btn-outline-danger col-6 py-3 my-2 my-md-3 active"
                        : "btn btn-outline-danger col-6 py-3 my-2 my-md-3"
                    }
                    onClick={() => setServiceType(serviceTypes.HOURS)}
                  >
                    Hourly Service
                  </button>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 my-2 my-md-3">
                  {serviceType === serviceTypes.PICKUP_DROPOFF && (
                    <div className="form-check py-3 col-6 d-inline-block ">
                      <input
                        className="form-check-input text-danger"
                        type="checkbox"
                        value={roundTrip}
                        id="flexCheckDefault"
                        onChange={() => setRoundTrip(!roundTrip)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Round Trip{" "}
                        <span className="round-discount badge bg-danger">
                          {roundTripDiscount}% discount
                        </span>
                      </label>
                    </div>
                  )}
                  {serviceType === serviceTypes.HOURS && (
                    <div className="col-6 d-inline-block my-2 my-md-3">
                      <select
                        className="form-select py-2"
                        id="floatingVehicle"
                        placeholder="Select a Vehicle"
                        aria-label="Select Vehicle"
                        value={serviceHours}
                        onChange={(e) => setServiceHours(e.target.value)}
                      >
                        <option value="" disabled>
                          How many time?
                        </option>
                        {hours?.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour} hours
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>

              {serviceType === serviceTypes.PICKUP_DROPOFF && roundTrip && (
                <div className="row gx-2">
                  <div className="col-6 d-inline-block mb-3 my-md-3">
                    <div className="form-floating">
                      <input
                        type="date"
                        className="form-control"
                        id="floatingReturnDate"
                        placeholder="Return Date"
                        min={pickupDate}
                        value={returnDate}
                        onChange={(e) => setReturnDate(e.target.value)}
                      />
                      <label htmlFor="floatingReturnDate">Return Date</label>
                    </div>
                  </div>
                  <div className="col-6 d-inline-block mb-3 my-md-3">
                    <div className="form-floating">
                      <input
                        type="time"
                        className="form-control"
                        id="floatingReturnTime"
                        placeholder="Return Time"
                        value={returnTime}
                        onChange={(e) => setReturnTime(e.target.value)}
                      />
                      <label htmlFor="floatingReturnTime">Return Time</label>
                    </div>
                  </div>
                </div>
              )}

              <div className="row gx-2">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={() => setStep(step - 1)}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default TravelForm;
