import React from "react";
import { NavLink } from "react-router-dom";

import iconLanguage from "../../assets/icon-language.svg";
import iconPhone from "../../assets/icon-phone.svg";
import iconLocation from "../../assets/icon-location.svg";
import logo from "../../assets/logo.png";

const activePages = {
  HOME: true,
  ABOUT: false,
  FLEET: false,
  RESERVE: false,
  TERMS: true,
  CONTACT: false,
};

function Menu() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container-fluid top-menu">
        <div className="container">
          <span className="clickable top-menu--language">
            <img src={iconLanguage} alt="Icon Language" />
            Language
          </span>
          <span className="clickable top-menu--phone">
            <img src={iconPhone} alt="Icon Phone" />
            +1(718) 404-9711
          </span>
          <span className="clickable top-menu--location">
            <img src={iconLocation} alt="Icon Location" />
            Park Avenue, Suite 1700, New York, NY 10166
          </span>
        </div>
      </div>
      <div className="container">
        <a className="navbar-brand" href="##">
          <img src={logo} alt="BestLimo logo" height="30" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {activePages.HOME && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/home"
                >
                  Home
                </NavLink>
              </li>
            )}

            {activePages.ABOUT && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/about"
                >
                  About us
                </NavLink>
              </li>
            )}

            {activePages.FLEET && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/fleet"
                >
                  Our Fleet
                </NavLink>
              </li>
            )}

            {activePages.RESERVE && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/reserve"
                >
                  Reserve
                </NavLink>
              </li>
            )}

            {activePages.TERMS && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/terms"
                >
                  Terms
                </NavLink>
              </li>
            )}

            {activePages.CONTACT && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
