export default {
  sedan:
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fsedan%2Fmini-00.png?alt=media&token=19521d88-31b0-4a93-8370-4476d2be4ce7",
  suv: "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fsuv%2Fmini-00.png?alt=media&token=714b91b9-6941-4328-89a9-d809fed287d9",
  "limo-chrysler":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Flimo-chrysler%2Fmini-00.png?alt=media&token=460f5cdd-88bb-404e-a501-4f9d1a3376c6",
  "limo-mkt":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Flimo-lincoln%2Fmini-00.png?alt=media&token=f02f62d0-223d-436f-b942-47c4ce807c07",
  "limo-cadillac":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Flimo-cadillac%2Fmini-00.png?alt=media&token=58989982-16e2-40df-9a19-3b32c9f300f6",
  "limo-hummer":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Flimo-hummer%2Fmini-00.png?alt=media&token=bb974a6f-f122-4f4a-b62a-db82aefabc37",
  "party-bus-18":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fpartybus-18%2Fmini-00.png?alt=media&token=c1673040-8792-4835-83a3-9016e43e349b",
  "party-bus-28":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fpartybus-28%2Fmini-00.png?alt=media&token=c12707e8-3580-4de6-a19c-fb1027f3efa3",
  "party-bus-44":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fpartybus-44%2Fmini-00.png?alt=media&token=803f117d-9f82-4c6f-803a-a19d692ae7d1",
  sprinter:
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fsprinter-mercedes%2Fmini-00.png?alt=media&token=a57af6cc-7e70-4a4b-8ffd-76d1cc678cc9",
  "sprinter-party":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fsprinter-vip%2Fmini-00.png?alt=media&token=3083d584-ac57-435f-9110-b5afab85af06",
  "coach-bus-22":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fbus-22%2Fmini-00.png?alt=media&token=1bac3c40-d473-4d13-ae71-cd9a9d533058",
  "coach-bus-35":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fbus-36%2Fmini-00.png?alt=media&token=6d753cc3-c0fc-4570-94f6-20562ab65772",
  "coach-bus-56":
    "https://firebasestorage.googleapis.com/v0/b/bestlimo-nyc.appspot.com/o/vehicles%2Fbus-56%2Fmini-00.png?alt=media&token=0edf1d8a-db08-4deb-b496-09eab1150271",
};
