import React from "react";

import iconYelp from "../../assets/icon-yelp.svg";
import iconYoutube from "../../assets/icon-youtube.svg";
import iconFacebook from "../../assets/icon-facebook.svg";
import iconInstagram from "../../assets/icon-instagram.svg";
import iconTripAdvisor from "../../assets/icon-tripadvisor.svg";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer id="footer" className="section-dark">
      <div className="footer-icon-area d-flex justify-content-center">
        <a
          href={process.env.REACT_APP_YELP_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer-icon" src={iconYelp} alt="Icon Yelp" />
        </a>
        <a
          href={process.env.REACT_APP_YOUTUBE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer-icon" src={iconYoutube} alt="Icon Youtube" />
        </a>
        <a
          href={process.env.REACT_APP_FACEBOOK_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer-icon" src={iconFacebook} alt="Icon Facebook" />
        </a>
        <a
          href={process.env.REACT_APP_INSTAGRAM_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-icon"
            src={iconInstagram}
            alt="Icon Instagram"
          />
        </a>
        <a
          href={process.env.REACT_APP_TRIPADVISOR_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-icon"
            src={iconTripAdvisor}
            alt="Icon TripAdvisor"
          />
        </a>
      </div>

      <div className="container footer-bottom-area">
        Copyright &copy; {currentYear} | Best Limo NYC | All rights reserved
      </div>
    </footer>
  );
}

export default Footer;
