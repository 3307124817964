import React from 'react';

import promoCars from '../../assets/bg-promo-cars.png';
import iconJourneys from '../../assets/icon-journeys.svg';
import iconTickets from '../../assets/icon-tickets.svg';
import iconPercent from '../../assets/icon-percent.svg';
import iconHours from '../../assets/icon-hours.svg';

function Statistics() {
  return (
    <section id="statistics" className="section-white">
      <div className="container">

        <div className="d-flex flex-column align-items-center col-6 col-md-3 mb-5">
          <img src={iconJourneys} className="icon-statistics" alt="Complete Journeys" />
          <h3>6140</h3>
          <span>Complete Journeys</span>
        </div>

        <div className="d-flex flex-column align-items-center col-6 col-md-3 mb-5">
          <img src={iconTickets} className="icon-statistics" alt="Answered Tickets" />
          <h3>6,312</h3>
          <span>Answered Tickets</span>
        </div>

        <div className="d-flex flex-column align-items-center col-6 col-md-3 mb-5">
          <img src={iconPercent} className="icon-statistics" alt="Customer Satisfaction" />
          <h3>97%</h3>
          <span>Customer Satisfaction</span>
        </div>

        <div className="d-flex flex-column align-items-center col-6 col-md-3 mb-5">
          <img src={iconHours} className="icon-statistics" alt="Hours per Yearn" />
          <h3>7,008</h3>
          <span>Hours per Year</span>
        </div>

      </div>

      <img
        src={promoCars}
        className="img-fluid"
        alt="Three vehicle"
      />
    </section>
  );
}

export default Statistics;
