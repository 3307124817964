/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper/core";

import iconPax from "../../assets/icon-pax.svg";
import iconLuggage from "../../assets/icon-luggage.svg";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import monthsConstant from "../../helpers/monthsConstant";

SwiperCore.use([Autoplay, Navigation, Pagination]);

function Offers() {
  const { vehicles } = useSelector((state) => state);
  const [month] = useState(new Date());

  return (
    <section id="main-offers" className="section-white">
      <div className="container">
        <h2>{monthsConstant[month.getMonth()]}&lsquo;s Offers!</h2>
        <h5>Enjoy our services with the best prices in the City</h5>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          className="mySwiper"
        >
          {vehicles?.map((vehicle) => (
            <SwiperSlide key={JSON.stringify(vehicle)} className={vehicle.id}>
              <div className="vehicles-card--info">
                <h6>{vehicle.name}</h6>
                <span>
                  <img src={iconPax} alt="Icon Pax" />
                  {vehicle.paxMin}-{vehicle.paxMax}
                </span>
                <span>
                  <img src={iconLuggage} alt="Icon Luggage" />
                  {vehicle.luggage}
                </span>
              </div>
              <div className="vehicles-card--price">
                <h4>{vehicle.priceHour}</h4>
                <span>per hour</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Offers;
