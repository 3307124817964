/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBooking,
  saveBookingSQL,
  setBooking,
} from "../../../redux/reducers/bookingReducer";

const PaymentForm = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const booking = useSelector((state) => state.booking);

  const [cardHolderAddress, setCardHolderAddress] = useState("");
  const [cardHolderSuite, setCardHolderSuite] = useState("");
  const [cardHolderCity, setCardHolderCity] = useState("");
  const [cardHolderState, setCardHolderState] = useState("");
  const [creditCardName, setCreditCardName] = useState("");
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [creditCardExpiration, setCreditCardExpiration] = useState("");
  const [creditCardCvv, setCreditCardCvv] = useState("");
  const [creditCardZipCode, setCreditCardZipCode] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const hideCreditCardNumber = (cardNumber) => {
    const lastFourDigits = cardNumber.slice(-4);
    const maskedCreditCardNumber = `**** **** **** ${lastFourDigits}`;
    return maskedCreditCardNumber;
  };

  const handleNextStep = () => {
    const newBooking = {
      ...booking,
      cardholder: {
        address: cardHolderAddress,
        suite: cardHolderSuite,
        city: cardHolderCity,
        state: cardHolderState,
      },
      creditcard: {
        name: creditCardName,
        number: hideCreditCardNumber(creditCardNumber),
        expiration_date: creditCardExpiration,
        cvv: creditCardCvv,
        zipcode: creditCardZipCode,
      },
      promo_code: promoCode,
    };

    dispatch(setBooking(newBooking));
    saveBookingSQL(newBooking);
    saveBooking(newBooking);

    history.push("/thanks");
    // setStep(step + 1);
  };

  return (
    <section id="airline-form" className="section-light no-padding-top">
      <div className="container">
        <h2>Payment Information</h2>
        <h5>Last step for complete...!</h5>

        <div className="container offset-md-2">
          <form className="row">
            <div className="col-12 col-md-8">
              <div className="row gx-2">
                <div className="col-12 col-md-6 offset-md-3 d-inline-block">
                  <span>
                    <i className="bi bi-person-circle fs-4" /> Cardholder
                  </span>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCardHolderAddress"
                      placeholder="Cardholder Address"
                      value={cardHolderAddress}
                      onChange={(e) => setCardHolderAddress(e.target.value)}
                    />
                    <label htmlFor="floatingCardHolderAddress">
                      Cardholder Address
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCardHolderSuite"
                      placeholder="Cardholder Suite"
                      value={cardHolderSuite}
                      onChange={(e) => setCardHolderSuite(e.target.value)}
                    />
                    <label htmlFor="floatingCardHolderSuite">Suite</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCardHolderCity"
                      placeholder="City"
                      value={cardHolderCity}
                      onChange={(e) => setCardHolderCity(e.target.value)}
                    />
                    <label htmlFor="floatingCardHolderCity">City</label>
                  </div>
                </div>

                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCardHolderState"
                      placeholder="State"
                      value={cardHolderState}
                      onChange={(e) => setCardHolderState(e.target.value)}
                    />
                    <label htmlFor="floatingCardHolderState">State</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2 mt-2">
                <div className="col-12 col-md-6 offset-md-3 d-inline-block">
                  <span>
                    <i className="bi bi-credit-card fs-4" /> Credit Card
                  </span>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCreditCardName"
                      placeholder="Card Name"
                      value={creditCardName}
                      onChange={(e) => setCreditCardName(e.target.value)}
                    />
                    <label htmlFor="floatingCreditCardName">Card Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCreditCardNumber"
                      placeholder="Card Number"
                      value={creditCardNumber}
                      onChange={(e) => setCreditCardNumber(e.target.value)}
                    />
                    <label htmlFor="floatingCreditCardNumber">
                      Card Number
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCreditCardExpiration"
                      placeholder="Expiration Date"
                      value={creditCardExpiration}
                      onChange={(e) => setCreditCardExpiration(e.target.value)}
                    />
                    <label htmlFor="floatingCreditCardExpiration">
                      Expiration Date
                    </label>
                  </div>
                </div>

                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCreditCardCvv"
                      placeholder="CVV"
                      value={creditCardCvv}
                      onChange={(e) => setCreditCardCvv(e.target.value)}
                    />
                    <label htmlFor="floatingCreditCardCvv">CVV</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCreditCardZipCode"
                      placeholder="Zip Code"
                      value={creditCardZipCode}
                      onChange={(e) => setCreditCardZipCode(e.target.value)}
                    />
                    <label htmlFor="floatingCreditCardZipCode">Zip Code</label>
                  </div>
                </div>

                <div className="col-6 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingPromoCode"
                      placeholder="Promo Code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    <label htmlFor="floatingPromoCode">Promo Code</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2 mt-2">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={() => setStep(step - 1)}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={handleNextStep}
                  >
                    Finish
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PaymentForm;
