import React from "react";

import HeaderSecondary from "../../components/HeaderSecondary";

function Contact() {
  return (
    <>
      <HeaderSecondary
        pageTitle="Contact Us"
        pageSubtitle="Our dispatchers available 24/7"
      />
      <section id="main-offers" className="section-white">
        <div className="container">
          Lorem ipsum dolor sit amet, consectetur adip
        </div>
      </section>
    </>
  );
}

export default Contact;
