/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/style-prop-object */
import React from "react";
import VideoPlayer from "react-background-video-player";

const URL =
  "https://a6e8z9v6.stackpathcdn.com/limoking/yellow/wp-content/uploads/2015/12/service-video-bg.mp4";

function Promo() {
  return (
    <section id="promo" className="section-dark transparent">
      <VideoPlayer className="video" src={URL} autoPlay muted />
      <h2 className="mb-5">
        Experience the luxurious side
        <br />
        of New York transportation
      </h2>
      <h6>
        No matter what the destination, our luxury car service will get you
        there in style.
      </h6>
      <h3>
        Call Now{" "}
        <a className="link link-light" href="tel:+17184049711">
          +1(718) 404-9711
        </a>
      </h3>
      <h6 className="text-small mt-3 mb-3">OR</h6>
      <button type="button" className="btn btn-secondary">
        Book Online
      </button>
    </section>
  );
}

export default Promo;
