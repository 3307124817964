/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBooking } from "../../../redux/reducers/bookingReducer";

const PersonalForm = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.booking);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneExtra, setPhoneExtra] = useState("");
  const [petTransportation, setPetTransportation] = useState(false);
  const [carSeat, setCarSeat] = useState(false);
  const [specialNeeds, setSpecialNeeds] = useState("");

  const handleSpecialNeeds = (event) => {
    setSpecialNeeds(event.target.value);
  };

  const handleNextStep = () => {
    const newBooking = {
      ...booking,
      passenger: name,
      email,
      phone_number: phone,
      phone_number_optional: phoneExtra,
      pet_transportation: petTransportation,
      car_seat: carSeat,
      special_needs: specialNeeds,
    };

    dispatch(setBooking(newBooking));
    setStep(step + 1);
  };

  return (
    <section id="personal-form" className="section-light no-padding-top">
      <div className="container">
        <h2>Personal Information!</h2>
        <h5>Something about you... the traveler!</h5>

        <div className="container offset-md-2">
          <form className="row">
            <div className="col-12 col-md-8">
              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingName"
                      placeholder="Passenger Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="floatingName">Passenger Full Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingEmail"
                      placeholder="Passenger E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingEmail">E-mail</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingPhone"
                      placeholder="Passenger Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      inputMode="numeric"
                    />
                    <label htmlFor="floatingPhone">
                      Passenger Phone Number
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 col-md-6 my-4 mb-md-2">
                  <div className="form-check pb-3 col-12 d-inline-block text-start">
                    <input
                      className="form-check-input text-danger"
                      type="checkbox"
                      value={petTransportation}
                      id="flexCheckPet"
                      onChange={() => setPetTransportation(!petTransportation)}
                    />
                    <label className="form-check-label" htmlFor="flexCheckPet">
                      Pet Transportation
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-2 my-md-3">
                  <div className="form-check pb-3 col-12 d-inline-block text-start">
                    <input
                      className="form-check-input text-danger"
                      type="checkbox"
                      value={carSeat}
                      id="flexCheckCar"
                      onChange={() => setCarSeat(!carSeat)}
                    />
                    <label className="form-check-label" htmlFor="flexCheckCar">
                      Car Seat
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 my-2 my-md-3">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                      style={{ height: "100px" }}
                      onChange={handleSpecialNeeds}
                    />
                    <label htmlFor="floatingTextarea">
                      Comments/Special Needs
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={() => setStep(step - 1)}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PersonalForm;
