/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import airlinesList from "../../../helpers/airlinesList";
import { setBooking } from "../../../redux/reducers/bookingReducer";

const AirlineForm = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.booking);
  const airlines = airlinesList
    .map((airline) =>
      airline.Country === "United States" ? airline.Name : null
    )
    .filter((airline) => airline);

  const [airline, setAirline] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [cityDeparture, setCityDeparture] = useState("");
  const [luggage, setLuggage] = useState("");
  const [meetGreet, setMeetGreet] = useState(false);

  const handleNextStep = () => {
    const newBooking = {
      ...booking,
      airline,
      flight_number: flightNumber,
      city_of_departure: cityDeparture,
      number_of_luggage: luggage,
      meet_and_greet: meetGreet,
    };

    dispatch(setBooking(newBooking));
    setStep(step + 1);
  };

  return (
    <section id="airline-form" className="section-light no-padding-top">
      <div className="container">
        <h2>Airline Information!</h2>
        <h5>Optional!</h5>

        <div className="container offset-md-2">
          <form className="row">
            <div className="col-12 col-md-8">
              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingAirline"
                      placeholder="Airline Name"
                      value={airline}
                      list="airlinesUS"
                      onChange={(e) => setAirline(e.target.value)}
                    />
                    <datalist id="airlinesUS">
                      {airlines.map((a) => (
                        <option value={a} key={a} />
                      ))}
                    </datalist>
                    <label htmlFor="floatingAirline">Airline Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingFlight"
                      placeholder="Passenger E-mail"
                      value={flightNumber}
                      onChange={(e) => setFlightNumber(e.target.value)}
                    />
                    <label htmlFor="floatingFlight">Flight Number</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 col-md-6 d-inline-block my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingCity"
                      placeholder="Flying From"
                      value={cityDeparture}
                      onChange={(e) => setCityDeparture(e.target.value)}
                    />
                    <label htmlFor="floatingCity">Flying From</label>
                  </div>
                </div>

                <div className="col-12 col-md-6 my-2 my-md-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingLuggage"
                      placeholder="Luggage"
                      value={luggage}
                      onChange={(e) => setLuggage(e.target.value)}
                      inputMode="numeric"
                    />
                    <label htmlFor="floatingLuggage">Luggage Qty</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-12 my-2 my-md-3">
                  <div className="form-check py-3 col-12 d-inline-block text-start">
                    <input
                      className="form-check-input text-danger"
                      type="checkbox"
                      value={meetGreet}
                      id="flexCheckGreet"
                      onChange={() => setMeetGreet(!meetGreet)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckGreet"
                    >
                      Meet & Greet (pick up inside){" "}
                      <span className="round-discount badge bg-danger">
                        + $25
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={() => setStep(step - 1)}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-block btn-danger btn-lg col-12 col-md-12 my-2 my-md-3"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AirlineForm;
