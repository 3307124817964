export default [
  { Name: "40 Mile Air", Country: "United States", IATA: "Q5" },
  { Name: "9 Air", Country: "China", IATA: "AQ" },
  {
    Name: "Abu Dhabi Amiri Flight",
    Country: "United Arab Emirates",
    IATA: "MO",
  },
  { Name: "ABX Air", Country: "United States", IATA: "GB" },
  { Name: "ACT Airlines", Country: "Turkey", IATA: "9T" },
  { Name: "Adria Airways", Country: "Slovenia", IATA: "JP" },
  { Name: "Aegean Airlines", Country: "Greece", IATA: "A3" },
  { Name: "Aer Lingus", Country: "Ireland", IATA: "EI" },
  { Name: "Aer Lingus Regional", Country: "Ireland", IATA: "EI" },
  { Name: "Aero Charter", Country: "Ukraine", IATA: "DW" },
  { Name: "Aero Express Del Ecuador", Country: "Ecuador", IATA: "7T" },
  { Name: "Aero Mongolia", Country: "Mongolia", IATA: "M0" },
  { Name: "Aero VIP", Country: "Portugal", IATA: "WV" },
  { Name: "Aerocontractors", Country: "Nigeria", IATA: "AJ" },
  {
    Name: "Aeroflot Russian Airlines",
    Country: "Russian Federation",
    IATA: "SU",
  },
  { Name: "Aerogaviota", Country: "Cuba", IATA: "KG" },
  { Name: "Aerol�neas Argentinas", Country: "Argentina", IATA: "AR" },
  { Name: "Aerol�neas Sosa", Country: "Honduras", IATA: "S0" },
  { Name: "Aerolink Uganda", Country: "Uganda", IATA: "A8" },
  { Name: "Aerologic", Country: "Germany", IATA: "3S" },
  { Name: "Aeromar", Country: "Mexico", IATA: "VW" },
  { Name: "Aeromexico", Country: "Mexico", IATA: "AM" },
  { Name: "Aeromexico Connect", Country: "Mexico", IATA: "5D" },
  { Name: "Aeropostal � Alas de Venezuela", Country: "Venezuela", IATA: "VH" },
  { Name: "AeroSucre", Country: "Colombia", IATA: "6N" },
  { Name: "Aerotrans Cargo", Country: "Moldova", IATA: "F5" },
  { Name: "AeroUnion", Country: "Mexico", IATA: "6R" },
  { Name: "Aerov�as DAP", Country: "Chile", IATA: "V5" },
  { Name: "Africa World Airlines", Country: "Ghana", IATA: "AW" },
  { Name: "African Express Airways", Country: "Kenya", IATA: "XU" },
  { Name: "Afrijet Business Service", Country: "Gabon", IATA: "J7" },
  { Name: "Afriqiyah Airways", Country: "Libya", IATA: "8U" },
  { Name: "Aigle Azur", Country: "France", IATA: "ZI" },
  { Name: "Air Alg�rie", Country: "Algeria", IATA: "AH" },
  { Name: "Air Antilles", Country: "French Guiana", IATA: "3S" },
  { Name: "Air Arabia", Country: "United Arab Emirates", IATA: "G9" },
  { Name: "Air Arabia Egypt", Country: "Egypt", IATA: "E5" },
  { Name: "Air Arabia Jordan", Country: "Jordan", IATA: "9P" },
  { Name: "Air Arabia Maroc", Country: "Morocco", IATA: "3O" },
  { Name: "Air Armenia", Country: "Armenia", IATA: "QN" },
  { Name: "Air Astana", Country: "Kazakhstan", IATA: "KC" },
  { Name: "Air Atlanta Icelandic", Country: "Iceland", IATA: "CC" },
  { Name: "Air Austral", Country: "R�union", IATA: "UU" },
  { Name: "Air Belgium", Country: "Belgium", IATA: "KF" },
  { Name: "Air Berlin", Country: "Germany", IATA: "AB" },
  { Name: "Air Bishkek", Country: "Kyrgyzstan", IATA: "KR" },
  { Name: "Air Botswana", Country: "Botswana", IATA: "BP" },
  { Name: "Air Bucharest", Country: "Romania", IATA: "B1" },
  { Name: "Air Burkina", Country: "Burkina Faso", IATA: "2J" },
  { Name: "Air Busan", Country: "South Korea", IATA: "BX" },
  { Name: "Air Cairo", Country: "Egypt", IATA: "SM" },
  { Name: "Air Cal�donie", Country: "New Caledonia", IATA: "TY" },
  { Name: "Air Canada", Country: "Canada", IATA: "AC" },
  { Name: "Air Canada Rouge", Country: "Canada", IATA: "RV" },
  { Name: "Air Cara�bes", Country: "Guadeloupe", IATA: "TX" },
  { Name: "Air Cargo Carriers", Country: "United States", IATA: "2Q" },
  { Name: "Air Cargo Global", Country: "Slovakia", IATA: "CW" },
  { Name: "Air Chang'an", Country: "China", IATA: "9H" },
  { Name: "Air Chathams", Country: "New Zealand", IATA: "3C" },
  { Name: "Air China", Country: "China", IATA: "CA" },
  { Name: "Air China Cargo", Country: "China", IATA: "CA" },
  { Name: "Air Choice One", Country: "United States", IATA: "3E" },
  { Name: "Air Corsica", Country: "France", IATA: "XK" },
  { Name: "Air C�te d'Ivoire", Country: "Ivory Coast", IATA: "HF" },
  { Name: "Air Creebec", Country: "Canada", IATA: "YN" },
  { Name: "Air Deccan", Country: "India", IATA: "DN" },
  { Name: "Air Djibouti", Country: "Djibouti", IATA: "IV" },
  { Name: "Air Do", Country: "Japan", IATA: "HD" },
  { Name: "Air Dolomiti", Country: "Italy", IATA: "EN" },
  { Name: "Air Europa", Country: "Spain", IATA: "UX" },
  { Name: "Air Excursions, LLC", Country: "United States", IATA: "X4" },
  { Name: "Air Flamenco", Country: "Puerto Rico", IATA: "F4" },
  { Name: "Air France", Country: "France", IATA: "AF" },
  { Name: "Air Georgian", Country: "Canada", IATA: "ZX" },
  { Name: "Air Greenland", Country: "Greenland", IATA: "GL" },
  { Name: "Air Guilin", Country: "China", IATA: "GT" },
  { Name: "Air Guyane Express", Country: "French Guiana", IATA: "3S" },
  { Name: "Air Hong Kong", Country: "Hong Kong", IATA: "LD" },
  { Name: "Air Iceland Connect", Country: "Iceland", IATA: "NY" },
  { Name: "Air Incheon", Country: "South Korea", IATA: "KJ" },
  { Name: "Air India", Country: "India", IATA: "AI" },
  { Name: "Air India Express", Country: "India", IATA: "IX" },
  { Name: "Air Indus", Country: "Pakistan", IATA: "I6" },
  { Name: "Air Inuit", Country: "Canada", IATA: "3H" },
  { Name: "Air Italy", Country: "Italy", IATA: "IG" },
  { Name: "Air Japan", Country: "Japan", IATA: "NQ" },
  { Name: "Air Juan", Country: "Philippines", IATA: "AO" },
  { Name: "Air KBZ", Country: "Myanmar", IATA: "K7" },
  { Name: "Air Kiribati", Country: "Kiribati", IATA: "IK" },
  { Name: "Air Koryo", Country: "North Korea", IATA: "JS" },
  { Name: "Air Labrador", Country: "Canada", IATA: "WJ" },
  { Name: "Air Leisure", Country: "Egypt", IATA: "AL" },
  { Name: "Air Liaison", Country: "Canada", IATA: "Q9" },
  { Name: "Air Libya", Country: "Libya", IATA: "7Q" },
  { Name: "Air Macau", Country: "Macao", IATA: "NX" },
  { Name: "Air Madagascar", Country: "Madagascar", IATA: "MD" },
  { Name: "Air Mali", Country: "Mali", IATA: "I5" },
  { Name: "Air Malta", Country: "Malta", IATA: "KM" },
  { Name: "Air Mandalay", Country: "Myanmar", IATA: "6T" },
  { Name: "Air Manus", Country: "Turkey", IATA: "ZM" },
  { Name: "Air Marshall Islands", Country: "Marshall islands", IATA: "CW" },
  { Name: "Air Mauritius", Country: "Mauritius", IATA: "MK" },
  { Name: "Air M�diterran�e", Country: "France", IATA: "ML" },
  { Name: "Air Moldova", Country: "Moldova", IATA: "9U" },
  { Name: "Air Namibia", Country: "Namibia", IATA: "SW" },
  { Name: "Air New Zealand", Country: "New Zealand", IATA: "NZ" },
  { Name: "Air New Zealand Link", Country: "New Zealand", IATA: "NZ" },
  { Name: "Air Niamey", Country: "Niger", IATA: "A7" },
  { Name: "Air Niugini", Country: "Papua New Guinea", IATA: "PX" },
  { Name: "Air North", Country: "Canada", IATA: "4N" },
  { Name: "Air Nostrum", Country: "Spain", IATA: "YW" },
  { Name: "Air Odisha", Country: "India", IATA: "6X" },
  { Name: "Air Panama", Country: "Panama", IATA: "7P" },
  { Name: "Air Peace", Country: "Nigeria", IATA: "P4" },
  { Name: "Air Pohang", Country: "South Korea", IATA: "AB" },
  { Name: "Air Rarotonga", Country: "Cook Islands", IATA: "GZ" },
  {
    Name: "Air Saint-Pierre",
    Country: "Saint Pierre and Miquelon",
    IATA: "PJ",
  },
  { Name: "Air Senegal", Country: "Senegal", IATA: "HC" },
  { Name: "Air Seoul", Country: "South Korea", IATA: "RS" },
  { Name: "Air Serbia", Country: "Serbia", IATA: "JU" },
  { Name: "Air Seychelles", Country: "Seychelles", IATA: "HM" },
  { Name: "Air Sinai", Country: "Egypt", IATA: "4D" },
  { Name: "Air Sunshine", Country: "United States", IATA: "YI" },
  { Name: "Air Tahiti", Country: "French Polynesia", IATA: "VT" },
  { Name: "Air Tahiti Nui", Country: "French Polynesia", IATA: "TN" },
  { Name: "Air Tanzania", Country: "Tanzania", IATA: "TC" },
  { Name: "Air Timor", Country: "Timor", IATA: "6C" },
  { Name: "Air Tindi", Country: "United States", IATA: "8T" },
  { Name: "Air Transat", Country: "Canada", IATA: "TS" },
  {
    Name: "Air Transport International (ATI)",
    Country: "United States",
    IATA: "8C",
  },
  { Name: "Air Urga", Country: "Ukraine", IATA: "3N" },
  { Name: "Air Vanuatu", Country: "Vanuatu", IATA: "NF" },
  { Name: "Air VIA", Country: "Bulgaria", IATA: "VL" },
  { Name: "Air Wisconsin", Country: "United States", IATA: "ZW" },
  { Name: "Air Zimbabwe", Country: "Zimbabwe", IATA: "UM" },
  { Name: "AirAsia", Country: "Malaysia", IATA: "AK" },
  { Name: "AirAsia India", Country: "India", IATA: "I5" },
  { Name: "AirAsia Japan", Country: "Japan", IATA: "DJ" },
  { Name: "AirAsia X", Country: "Malaysia", IATA: "D7" },
  { Name: "airBaltic", Country: "Latvia", IATA: "BT" },
  { Name: "Airblue", Country: "Pakistan", IATA: "PA" },
  { Name: "AirBridge Cargo", Country: "Russian Federation", IATA: "RU" },
  { Name: "Airbus", Country: "UK/France/Germany/Spain", IATA: "VK" },
  { Name: "Airbus Transport International", Country: "France", IATA: "4Y" },
  { Name: "Aircalin", Country: "New Caledonia", IATA: "SB" },
  { Name: "AirExplore", Country: "Slovakia", IATA: "ED" },
  { Name: "Airfast Indonesia", Country: "Indonesia", IATA: "FS" },
  { Name: "Airkenya Express", Country: "Kenya", IATA: "P2" },
  { Name: "Airlinair", Country: "France", IATA: "AN" },
  { Name: "Airlink", Country: "South Africa", IATA: "4Z" },
  { Name: "Airnorth", Country: "Australia", IATA: "TL" },
  { Name: "AirSWIFT", Country: "Philippines", IATA: "T6" },
  { Name: "AirTanker", Country: "United Kingdom", IATA: "9L" },
  { Name: "AIS Airlines", Country: "Netherlands", IATA: "IS" },
  { Name: "Aklak Air", Country: "Canada", IATA: "6L" },
  { Name: "Al Naser Wings", Country: "Iraq", IATA: "NG" },
  { Name: "Alaska Airlines", Country: "United States", IATA: "AS" },
  { Name: "Alaska Central Express", Country: "United States", IATA: "KO" },
  {
    Name: "Alaska Seaplane Service L.L.C.",
    Country: "United States",
    IATA: "J5",
  },
  { Name: "AlbaStar", Country: "Spain", IATA: "AP" },
  { Name: "Albatros Airlines", Country: "Venezuela", IATA: "G2" },
  { Name: "Albawings", Country: "Albania", IATA: "2B" },
  { Name: "Alexandria Airlines", Country: "Egypt", IATA: "ZR" },
  { Name: "Alidaunia", Country: "Italy", IATA: "D4" },
  { Name: "Alitalia", Country: "Italy", IATA: "AZ" },
  { Name: "Alitalia CityLiner", Country: "Italy", IATA: "CT" },
  { Name: "All Nippon Airways (ANA)", Country: "Japan", IATA: "NH" },
  { Name: "Allegiant Air", Country: "United States", IATA: "G4" },
  { Name: "Alliance Air", Country: "India", IATA: "9I" },
  { Name: "Alliance Airlines", Country: "Australia", IATA: "QQ" },
  { Name: "Allied Air", Country: "Nigeria", IATA: "4W" },
  { Name: "AlMasria Universal Airlines", Country: "Egypt", IATA: "UJ" },
  { Name: "Aloha Air Cargo", Country: "United States", IATA: "KH" },
  { Name: "Alpine Aviation", Country: "N/A", IATA: "5A" },
  {
    Name: "Alrosa Mirny Air Enterprise",
    Country: "Russian Federation",
    IATA: "6R",
  },
  { Name: "ALS Ltd", Country: "Kenya", IATA: "K4" },
  { Name: "Alsie Express", Country: "Denmark", IATA: "6I" },
  { Name: "Amakusa Airlines", Country: "Japan", IATA: "MZ" },
  { Name: "Amapola Flyg", Country: "Sweden", IATA: "HP" },
  { Name: "Amaszonas", Country: "Bolivia", IATA: "Z8" },
  { Name: "Amaszonas Paraguay", Country: "Paraguay", IATA: "ZP" },
  { Name: "Amaszonas Uruguay", Country: "Uruguay", IATA: "Z7" },
  { Name: "AMC Airlines", Country: "Egypt", IATA: "YJ" },
  { Name: "American Airlines", Country: "United States", IATA: "AA" },
  { Name: "Ameriflight", Country: "United States", IATA: "A8" },
  { Name: "Amerijet International", Country: "United States", IATA: "M6" },
  { Name: "ANA Wings", Country: "Japan", IATA: "EH" },
  { Name: "AnadoluJet", Country: "Turkey", IATA: "TK" },
  { Name: "Andes L�neas A�reas", Country: "Argentina", IATA: "OY" },
  { Name: "Angara Airlines", Country: "Russian Federation", IATA: "2G" },
  { Name: "Anguilla Air Services", Country: "Anguilla", IATA: "Q3" },
  { Name: "Anisec", Country: "Austria", IATA: "VK" },
  { Name: "APG Airlines", Country: "France", IATA: "GP" },
  { Name: "Ariana Afghan Airlines", Country: "Afghanistan", IATA: "FG" },
  { Name: "Arik Air", Country: "Nigeria", IATA: "W3" },
  { Name: "Arkia Israeli Airlines", Country: "Israel", IATA: "IZ" },
  { Name: "Armenia Aircompany", Country: "Armenia", IATA: "RM" },
  { Name: "Aruba Airlines", Country: "Aruba", IATA: "AG" },
  { Name: "Aserca Airlines", Country: "Venezuela", IATA: "R7" },
  { Name: "Asia Atlantic Airlines", Country: "Thailand", IATA: "HB" },
  { Name: "AsiaCargo Express", Country: "Malaysia", IATA: "3G" },
  { Name: "Asian Express Airline", Country: "Tajikistan", IATA: "KV" },
  { Name: "Asian Wings Airways", Country: "Myanmar", IATA: "YJ" },
  { Name: "Asiana Airlines", Country: "South Korea", IATA: "OZ" },
  { Name: "ASKY Airlines", Country: "Togo", IATA: "KP" },
  { Name: "ASL Airlines Belgium", Country: "Belgium", IATA: "3V" },
  { Name: "ASL Airlines France", Country: "France", IATA: "5O" },
  { Name: "ASL Airlines Ireland", Country: "Ireland", IATA: "AG" },
  { Name: "Astra Airlines", Country: "Greece", IATA: "A2" },
  { Name: "Astral Aviation", Country: "Kenya", IATA: "8V" },
  { Name: "ATA Airlines", Country: "Iran", IATA: "I3" },
  { Name: "Atlantic Airways", Country: "Faroe Islands", IATA: "RC" },
  { Name: "Atlantis European Airways", Country: "Armenia", IATA: "TD" },
  { Name: "Atlas Air", Country: "United States", IATA: "5Y" },
  { Name: "AtlasGlobal", Country: "Turkey", IATA: "KK" },
  { Name: "AtlasGlobal Ukraine", Country: "Ukraine", IATA: "UH" },
  { Name: "ATRAN", Country: "Russian Federation", IATA: "V8" },
  { Name: "ATU Cargo", Country: "Russian Federation", IATA: "DV" },
  { Name: "Auric Air Services", Country: "Tanzania", IATA: "UI" },
  { Name: "Aurigny Air Services", Country: "Channel islands", IATA: "GR" },
  { Name: "Aurora", Country: "Russian Federation", IATA: "HZ" },
  { Name: "Austral L�neas A�reas", Country: "Argentina", IATA: "AU" },
  { Name: "Austrian Airlines", Country: "Austria", IATA: "OS" },
  { Name: "Avia Traffic Company", Country: "Kyrgyzstan", IATA: "YK" },
  { Name: "Aviacon Zitotrans", Country: "Russian Federation", IATA: "ZR" },
  { Name: "Avianca", Country: "Colombia", IATA: "AV" },
  { Name: "Avianca Argentina", Country: "Argentina", IATA: "A0" },
  { Name: "Avianca Brasil", Country: "Brazil", IATA: "O6" },
  { Name: "Avianca Cargo", Country: "Colombia", IATA: "QT" },
  { Name: "Avianca Costa Rica", Country: "Costa Rica", IATA: "LR" },
  { Name: "Avianca Ecuador", Country: "Ecuador", IATA: "2K" },
  { Name: "Avianca El Salvador", Country: "El Salvador", IATA: "TA" },
  { Name: "Avianca Honduras", Country: "Honduras", IATA: "WC" },
  { Name: "Avianca Peru", Country: "Peru", IATA: "T0" },
  { Name: "Aviastar", Country: "Indonesia", IATA: "MV" },
  { Name: "Aviastar-TU", Country: "Russian Federation", IATA: "4B" },
  { Name: "Aviateca", Country: "Guatemala", IATA: "GU" },
  { Name: "Avion Express", Country: "Lithuania", IATA: "X9" },
  { Name: "Avior Airlines", Country: "Venezuela", IATA: "9V" },
  { Name: "Azerbaijan Airlines", Country: "Azerbaijan", IATA: "J2" },
  { Name: "Azimuth", Country: "Russian Federation", IATA: "A4" },
  { Name: "Azman Air", Country: "Nigeria", IATA: "ZQ" },
  { Name: "Azores Airlines", Country: "Portugal", IATA: "S4" },
  { Name: "Aztec Airways", Country: "United States", IATA: "AJ" },
  { Name: "Azul", Country: "Brazil", IATA: "AD" },
  { Name: "Azur Air", Country: "Russian Federation", IATA: "ZF" },
  { Name: "Azur Air Ukraine", Country: "Russian Federation", IATA: "QU" },
  { Name: "BA CityFlyer", Country: "United Kingdom", IATA: "CJ" },
  { Name: "Badr Airlines", Country: "Sudan", IATA: "J4" },
  { Name: "Bahamasair", Country: "Bahamas", IATA: "UP" },
  { Name: "Balkan Holidays Air", Country: "Bulgaria", IATA: "1B" },
  { Name: "Bamboo Airways", Country: "Vietnam", IATA: "QH" },
  { Name: "Bangkok Airways", Country: "Thailand", IATA: "PG" },
  { Name: "Bankair", Country: "United States", IATA: "B4" },
  { Name: "Bassaka Air", Country: "Cambodia", IATA: "5B" },
  { Name: "Batik Air", Country: "Indonesia", IATA: "ID" },
  { Name: "Bearskin Airlines", Country: "Canada", IATA: "JV" },
  { Name: "Beijing Capital Airlines", Country: "China", IATA: "JD" },
  { Name: "Bek Air", Country: "Kazakhstan", IATA: "Z9" },
  { Name: "Belavia Belarusian Airlines", Country: "Belarus", IATA: "B2" },
  { Name: "Bemidji Airlines", Country: "United States", IATA: "CH" },
  { Name: "Bering Air", Country: "United States", IATA: "8E" },
  { Name: "Berjaya Air", Country: "Malaysia", IATA: "J8" },
  { Name: "Bhutan Airlines", Country: "Bhutan", IATA: "B3" },
  { Name: "Biman Bangladesh Airlines", Country: "Bangladesh", IATA: "BG" },
  { Name: "Binter Canarias", Country: "Canary islands", IATA: "NT" },
  { Name: "Bismillah Airlines", Country: "Bangladesh", IATA: "BH" },
  { Name: "Blue Air", Country: "Romania", IATA: "0B" },
  { Name: "Blue Dart Aviation", Country: "India", IATA: "BZ" },
  { Name: "Blue Islands", Country: "Channel islands", IATA: "SI" },
  { Name: "Blue Panorama Airlines", Country: "Italy", IATA: "BV" },
  { Name: "Blue1", Country: "Finland", IATA: "KF" },
  { Name: "Bluebird Cargo", Country: "Iceland", IATA: "BO" },
  { Name: "Boliviana de Aviaci�n", Country: "Bolivia", IATA: "OB" },
  { Name: "Borajet", Country: "Turkey", IATA: "YB" },
  { Name: "Boutique Air", Country: "United States", IATA: "4B" },
  { Name: "Braathens Regional Airways", Country: "Sweden", IATA: "DC" },
  { Name: "Braathens Regional Aviation", Country: "Sweden", IATA: "TF" },
  { Name: "Branson AirExpress", Country: "Bemidji", IATA: "1X" },
  { Name: "Brava Linhas A�reas", Country: "Brazil", IATA: "N7" },
  { Name: "Bristow Group", Country: "United States", IATA: "L9" },
  { Name: "Bristow Helicopters", Country: "United Kingdom", IATA: "UH" },
  { Name: "Brit Air", Country: "France", IATA: "DB" },
  { Name: "British Airways", Country: "United Kingdom", IATA: "BA" },
  { Name: "Brussels Airlines", Country: "Belgium", IATA: "SN" },
  { Name: "Buddha Air", Country: "Nepal", IATA: "U4" },
  { Name: "Buffalo Airways", Country: "Canada", IATA: "J4" },
  { Name: "Bulgaria Air", Country: "Bulgaria", IATA: "FB" },
  { Name: "Bulgarian Air Charter", Country: "Bulgaria", IATA: "1T" },
  { Name: "Buraq Air", Country: "Libya", IATA: "UZ" },
  { Name: "Business Air", Country: "Thailand", IATA: "8B" },
  { Name: "Business Aviation Centre", Country: "Ukraine", IATA: "UQ" },
  { Name: "BVI Airways", Country: "British Virgin Islands", IATA: "XV" },
  { Name: "Cabo Verde Airlines", Country: "Cape Verde", IATA: "VR" },
  {
    Name: "Caicos Express Airways",
    Country: "Turks and Caicos Islands",
    IATA: "9Q",
  },
  { Name: "CAL Cargo Air Lines", Country: "Israel", IATA: "5C" },
  { Name: "Calafia Airlines", Country: "Mexico", IATA: "A7" },
  { Name: "California Pacific Airlines", Country: "United States", IATA: "4A" },
  { Name: "Calm Air", Country: "Canada", IATA: "MO" },
  { Name: "Camair-Co", Country: "Cameroon", IATA: "QC" },
  { Name: "Cambodia Angkor Air", Country: "Cambodia", IATA: "K6" },
  { Name: "Cambodia Bayon Airlines", Country: "Cambodia", IATA: "BD" },
  { Name: "Canadian North", Country: "Canada", IATA: "5T" },
  { Name: "Canarias Airlines", Country: "Spain", IATA: "NT" },
  { Name: "Canaryfly", Country: "Spain", IATA: "PM" },
  { Name: "Cape Air", Country: "United States", IATA: "9K" },
  { Name: "Cardig Air", Country: "Indonesia", IATA: "8F" },
  { Name: "Cargojet Airways", Country: "Canada", IATA: "W8" },
  { Name: "CargoLogicAir", Country: "United Kingdom", IATA: "P3" },
  { Name: "Cargolux", Country: "Luxembourg", IATA: "CV" },
  { Name: "Cargolux Italia", Country: "Luxembourg", IATA: "C8" },
  { Name: "Caribbean Airlines", Country: "Trinidad and Tobago", IATA: "BW" },
  { Name: "Carpatair", Country: "Romania", IATA: "V3" },
  { Name: "Caspian Airlines", Country: "Iran", IATA: "IV" },
  { Name: "Cathay Dragon", Country: "Hong Kong", IATA: "KA" },
  { Name: "Cathay Pacific Airways", Country: "Hong Kong", IATA: "CX" },
  { Name: "Cayman Airways", Country: "Cayman islands", IATA: "KX" },
  { Name: "Cebgo", Country: "Philippines", IATA: "DG" },
  { Name: "Cebu Pacific Air", Country: "Philippines", IATA: "5J" },
  { Name: "CEIBA Intercontinental", Country: "Equatorial Guinea", IATA: "C2" },
  { Name: "CemAir", Country: "South Africa", IATA: "5Z" },
  { Name: "Central Mountain Air", Country: "Canada", IATA: "9M" },
  { Name: "Centurion Air Cargo", Country: "United States", IATA: "WE" },
  { Name: "Chair Airlines", Country: "Switzerland", IATA: "GM" },
  { Name: "Chalair Aviation", Country: "France", IATA: "CE" },
  { Name: "Cham Wings Airlines", Country: "Syria", IATA: "6Q" },
  { Name: "Chautauqua Airlines", Country: "United States", IATA: "RP" },
  { Name: "Chengdu Airlines", Country: "China", IATA: "EU" },
  { Name: "China Airlines", Country: "Taiwan", IATA: "CI" },
  { Name: "China Cargo Airlines", Country: "China", IATA: "CK" },
  { Name: "China Eastern Airlines", Country: "China", IATA: "MU" },
  { Name: "China Express Airlines", Country: "China", IATA: "G5" },
  { Name: "China Postal Airlines", Country: "China", IATA: "CF" },
  { Name: "China Southern Airlines", Country: "China", IATA: "CZ" },
  { Name: "China United Airlines", Country: "China", IATA: "KN" },
  { Name: "Chongqing Airlines", Country: "China", IATA: "OQ" },
  { Name: "Cielos Airlines (Cielos del Peru)", Country: "Peru", IATA: "A2" },
  { Name: "Cinnamon Air", Country: "Sri Lanka", IATA: "C7" },
  { Name: "Citilink", Country: "Indonesia", IATA: "QG" },
  { Name: "City Airways", Country: "Thailand", IATA: "E8" },
  { Name: "CityJet", Country: "Ireland", IATA: "WX" },
  { Name: "CityLine Hungary", Country: "Hungary", IATA: "ZM" },
  { Name: "Click Airways", Country: "United Arab Emirates", IATA: "C4" },
  { Name: "CM Airlines", Country: "Honduras", IATA: "CC" },
  { Name: "Coastal Air Transport", Country: "US Virgin Islands", IATA: "DQ" },
  { Name: "Coastal Aviation", Country: "Tanzania", IATA: "CQ" },
  { Name: "Cobalt Air", Country: "Cyprus", IATA: "CO" },
  {
    Name: "Cobham Aviation Services Australia",
    Country: "Australia",
    IATA: "NC",
  },
  { Name: "Colorful Guizhou Airlines", Country: "China", IATA: "GY" },
  { Name: "Comair", Country: "South Africa", IATA: "MN" },
  { Name: "CommutAir", Country: "United States", IATA: "C5" },
  { Name: "Comores Aviation", Country: "Comoros", IATA: "O5" },
  { Name: "Compass Airlines", Country: "United States", IATA: "CP" },
  { Name: "Condor Flugdienst", Country: "Germany", IATA: "DE" },
  { Name: "Congo Airways", Country: "Republic of Congo", IATA: "8Z" },
  { Name: "Contour Airlines", Country: "United States", IATA: "LF" },
  { Name: "Conviasa", Country: "Venezuela", IATA: "V0" },
  { Name: "Copa Airlines", Country: "Panama", IATA: "CM" },
  { Name: "Corendon Airlines", Country: "Turkey", IATA: "XC" },
  { Name: "Corendon Airlines Europe", Country: "Malta", IATA: "XR" },
  { Name: "Corendon Dutch Airlines", Country: "Netherlands", IATA: "CD" },
  { Name: "Corsair", Country: "France", IATA: "SS" },
  { Name: "Coyne Airways", Country: "United Kingdom", IATA: "7C" },
  { Name: "Croatia Airlines", Country: "Croatia", IATA: "OU" },
  { Name: "Cronos Airlines", Country: "Equatorial Guinea", IATA: "C8" },
  { Name: "CSA Czech Airlines", Country: "Czech Republic", IATA: "OK" },
  { Name: "Cubana", Country: "Cuba", IATA: "CU" },
  { Name: "Cyprus Airways", Country: "Cyprus", IATA: "CY" },
  { Name: "Daallo Airlines", Country: "Djibouti", IATA: "D3" },
  { Name: "Dalian Airlines", Country: "China", IATA: "CA" },
  { Name: "Dana Air", Country: "Nigeria", IATA: "9J" },
  { Name: "Danish Air Transport", Country: "Denmark", IATA: "DX" },
  { Name: "Danube Wings", Country: "Slovakia", IATA: "V5" },
  { Name: "Delta Air Lines", Country: "United States", IATA: "DL" },
  { Name: "Denim Air", Country: "Netherlands", IATA: "G6" },
  { Name: "DHL Aero Expreso", Country: "Panama", IATA: "D5" },
  { Name: "DHL Air UK", Country: "United Kingdom", IATA: "D0" },
  { Name: "DHL de Guatemala, S.A.", Country: "Guatemala", IATA: "L3" },
  { Name: "DHL International E.C.", Country: "Bahrain", IATA: "ES" },
  { Name: "DirektFlyg", Country: "Sweden", IATA: "HS" },
  { Name: "Divi Divi Air", Country: "Cura�ao", IATA: "DI" },
  { Name: "Djibouti Air", Country: "Djibouti", IATA: "DA" },
  { Name: "Dniproavia", Country: "Ukraine", IATA: "Z6" },
  { Name: "Donavia", Country: "Russian Federation", IATA: "D9" },
  { Name: "Donghai Airlines", Country: "China", IATA: "DZ" },
  { Name: "DOT LT", Country: "Lithuania", IATA: "R6" },
  { Name: "Dragonair", Country: "Hong Kong", IATA: "KA" },
  { Name: "Druk Air (Royal Bhutan Airlines)", Country: "Bhutan", IATA: "KB" },
  { Name: "Eagle Air", Country: "Uganda", IATA: "H7" },
  { Name: "Eagle Air Iceland", Country: "Iceland", IATA: "IS" },
  { Name: "East African Express", Country: "Kenya", IATA: "B5" },
  { Name: "Eastar Jet", Country: "South Korea", IATA: "ZE" },
  { Name: "Eastern Airlines, LLC", Country: "United States", IATA: "2D" },
  { Name: "Eastern Airways", Country: "United Kingdom", IATA: "T3" },
  { Name: "Easyfly", Country: "Colombia", IATA: "VE" },
  { Name: "easyJet", Country: "United Kingdom", IATA: "U2" },
  { Name: "easyJet Europe", Country: "Austria", IATA: "EC" },
  { Name: "EasyJet Switzerland", Country: "Switzerland", IATA: "DS" },
  { Name: "EAT Leipzig", Country: "Belgium", IATA: "QY" },
  { Name: "Ecojet", Country: "Bolivia", IATA: "8J" },
  { Name: "Edelweiss Air", Country: "Switzerland", IATA: "WK" },
  { Name: "EgyptAir", Country: "Egypt", IATA: "MS" },
  { Name: "EL AL Israel Airlines", Country: "Israel", IATA: "LY" },
  { Name: "Elite Airways", Country: "United States", IATA: "7Q" },
  { Name: "Ellinair", Country: "Greece", IATA: "EL" },
  { Name: "Elysian Airlines", Country: "Cameroon", IATA: "E4" },
  { Name: "Emirates Airlines", Country: "United Arab Emirates", IATA: "EK" },
  { Name: "Empire Airlines", Country: "United States", IATA: "EM" },
  { Name: "Endeavor Air", Country: "United States", IATA: "9E" },
  { Name: "Enter Air", Country: "Poland", IATA: "E4" },
  { Name: "Envoy Air", Country: "United States", IATA: "MQ" },
  { Name: "Equaflight", Country: "Republic of Congo", IATA: "E7" },
  { Name: "Eritrean Airlines", Country: "Eritrea", IATA: "B8" },
  { Name: "Ernest Airways", Country: "Italy", IATA: "EG" },
  { Name: "Estafeta Carga A�rea", Country: "Mexico", IATA: "E7" },
  { Name: "Estelar Latinoamerica", Country: "Venezuela", IATA: "ES" },
  { Name: "Ethiopian Airlines", Country: "Ethiopia", IATA: "ET" },
  { Name: "Etihad Airways", Country: "United Arab Emirates", IATA: "EY" },
  { Name: "Euroatlantic Airways", Country: "Portugal", IATA: "YU" },
  { Name: "Eurowings", Country: "Germany", IATA: "EW" },
  { Name: "Eurowings Europe", Country: "Austria", IATA: "E2" },
  { Name: "EVA Air", Country: "Taiwan", IATA: "BR" },
  { Name: "Evelop Airlines", Country: "Spain", IATA: "E9" },
  { Name: "Everts Air", Country: "United States", IATA: "5V" },
  { Name: "Ewa Air", Country: "FRANCE", IATA: "ZD" },
  { Name: "Exploits Valley Air Services", Country: "Canada", IATA: "8K" },
  { Name: "ExpressJet", Country: "United States", IATA: "EV" },
  { Name: "EZAir", Country: "Curacao", IATA: "EZ" },
  { Name: "Eznis Airways", Country: "Mongolia", IATA: "MG" },
  { Name: "Far Eastern Air Transport (FAT)", Country: "Taiwan", IATA: "FE" },
  { Name: "Fastjet", Country: "Tanzania", IATA: "FN" },
  { Name: "Fastjet Zimbabwe", Country: "Zimbabwe", IATA: "FN" },
  { Name: "FedEx", Country: "United States", IATA: "FX" },
  { Name: "Felix Airways", Country: "Yemen", IATA: "FO" },
  { Name: "Fiji Airways", Country: "Fiji", IATA: "FJ" },
  { Name: "Fiji Link", Country: "Fiji", IATA: "FJ" },
  { Name: "Finnair", Country: "Finland", IATA: "AY" },
  { Name: "Firefly", Country: "Malaysia", IATA: "FY" },
  { Name: "First Air", Country: "Canada", IATA: "7F" },
  { Name: "FitsAir", Country: "Sri Lanka", IATA: "8D" },
  { Name: "Flair Airlines", Country: "Canada", IATA: "F8" },
  { Name: "FlexFlight ApS", Country: "Denmark", IATA: "W2" },
  {
    Name: "Florida West International Airways",
    Country: "United States",
    IATA: "RF",
  },
  { Name: "Fly All Ways", Country: "Suriname", IATA: "8W" },
  { Name: "Fly Baghdad", Country: "Iraq", IATA: "IF" },
  { Name: "Fly Corporate", Country: "Australia", IATA: "FC" },
  { Name: "Fly Jamaica Airways", Country: "Jamaica", IATA: "OJ" },
  { Name: "Fly Mid Africa", Country: "Gambia", IATA: "8G" },
  { Name: "Fly540", Country: "Kenya", IATA: "5H" },
  { Name: "flyadeal", Country: "Saudi Arabia", IATA: "F3" },
  { Name: "flyafrica.com", Country: "Zimbabwe", IATA: "Z7" },
  { Name: "FlyArystan", Country: "Kazakhstan", IATA: "KC" },
  { Name: "FlyBE", Country: "United Kingdom", IATA: "BE" },
  { Name: "Flybmi", Country: "United Kingdom", IATA: "BM" },
  { Name: "Flybondi", Country: "Argentina", IATA: "FO" },
  { Name: "flyCAA", Country: "Republic of Congo", IATA: "BU" },
  { Name: "FlyDamas", Country: "Syria", IATA: "4J" },
  { Name: "FlyDubai", Country: "United Arab Emirates", IATA: "FZ" },
  { Name: "FlyEgypt", Country: "Egypt", IATA: "FT" },
  { Name: "Flyme", Country: "Maldives", IATA: "VP" },
  { Name: "FlyMontserrat", Country: "Montserrat", IATA: "5M" },
  { Name: "flynas", Country: "Saudi Arabia", IATA: "XY" },
  { Name: "FLYONE", Country: "Moldova", IATA: "5F" },
  { Name: "FlyPelican", Country: "Australia", IATA: "FP" },
  { Name: "FlyValan", Country: "Romania", IATA: "F6" },
  { Name: "FMI Air", Country: "Myanmar", IATA: "ND" },
  { Name: "Freebird Airlines", Country: "Turkey", IATA: "FH" },
  { Name: "Freedom Air", Country: "Guam", IATA: "FP" },
  { Name: "French Bee", Country: "France", IATA: "BF" },
  { Name: "Frontier Airlines", Country: "United States", IATA: "F9" },
  { Name: "Frontier Flying Service", Country: "United States", IATA: "2F" },
  { Name: "Fuji Dream Airlines", Country: "Japan", IATA: "JH" },
  { Name: "Fuzhou Airlines", Country: "Japan", IATA: "FU" },
  { Name: "Garuda Indonesia", Country: "Indonesia", IATA: "GA" },
  { Name: "Gazpromavia", Country: "Russian Federation", IATA: "4G" },
  { Name: "Georgian Airlines", Country: "Georgia", IATA: "A9" },
  { Name: "Germania", Country: "Germany", IATA: "ST" },
  { Name: "Germanwings", Country: "Germany", IATA: "4U" },
  { Name: "Gestair Cargo", Country: "Spain", IATA: "XG" },
  { Name: "GetJet Airlines", Country: "Lithuania", IATA: "GW" },
  { Name: "Global Africa Aviation", Country: "Zimbabwe", IATA: "Z5" },
  { Name: "Global Aviation", Country: "South Africa", IATA: "GE" },
  { Name: "Global Aviation and Service Group", Country: "Libya", IATA: "5S" },
  { Name: "Globus", Country: "Russian Federation", IATA: "GH" },
  { Name: "Go2Sky", Country: "Slovakia", IATA: "6G" },
  { Name: "GoAir", Country: "India", IATA: "G8" },
  { Name: "GoJet Airlines", Country: "United States", IATA: "G7" },
  { Name: "Gol Transportes A�reos", Country: "Brazil", IATA: "G3" },
  { Name: "Golden Myanmar Airlines", Country: "Myanmar", IATA: "Y5" },
  { Name: "Grand China Airlines", Country: "China", IATA: "CN" },
  { Name: "Grant Aviation", Country: "United States", IATA: "GV" },
  { Name: "Great Lakes Airlines", Country: "United States", IATA: "ZK" },
  { Name: "Grozny Avia", Country: "Russian Federation", IATA: "ZG" },
  { Name: "Gryphon Airlines", Country: "United States", IATA: "6P" },
  { Name: "Gulf Air", Country: "Bahrain", IATA: "GF" },
  { Name: "GX Airlines", Country: "China", IATA: "GX" },
  { Name: "Hahn Air", Country: "Germany", IATA: "HR" },
  { Name: "Hainan Airlines", Country: "China", IATA: "HU" },
  { Name: "Harbour Air Seaplanes", Country: "Canada", IATA: "H3" },
  { Name: "Hawaiian Airlines", Country: "United States", IATA: "HA" },
  { Name: "HeavyLift Cargo Airlines", Country: "Australia", IATA: "HN" },
  { Name: "Hebei Airlines", Country: "China", IATA: "NS" },
  { Name: "Helvetic Airways", Country: "Switzerland", IATA: "2L" },
  { Name: "Hi Fly", Country: "Portugal", IATA: "5K" },
  { Name: "Himalaya Airlines", Country: "Nepal", IATA: "H9" },
  { Name: "Hinterland Aviation", Country: "Australia", IATA: "OI" },
  { Name: "Hokkaido Air System", Country: "Japan", IATA: "6L" },
  { Name: "Hong Kong Air Cargo", Country: "Hong Kong", IATA: "RH" },
  { Name: "Hong Kong Airlines", Country: "China", IATA: "HX" },
  { Name: "Hong Kong Express", Country: "Hong Kong", IATA: "UO" },
  { Name: "Hongtu Airlines", Country: "China", IATA: "A6" },
  { Name: "HOP!", Country: "France", IATA: "A5" },
  { Name: "Horizon Air", Country: "United States", IATA: "QX" },
  { Name: "Hunnu Air", Country: "Mongolia", IATA: "MR" },
  { Name: "I-Fly", Country: "Russian Federation", IATA: "H5" },
  { Name: "IBC Airways", Country: "United States", IATA: "II" },
  { Name: "Iberia", Country: "Spain", IATA: "IB" },
  { Name: "Iberia Express", Country: "Spain", IATA: "I2" },
  { Name: "Ibex Airlines", Country: "Japan", IATA: "FW" },
  { Name: "Icelandair", Country: "Iceland", IATA: "FI" },
  { Name: "Iliamna Air Taxi", Country: "United States", IATA: "V8" },
  { Name: "IndiGo", Country: "India", IATA: "6E" },
  { Name: "Indonesia Air Transport", Country: "Indonesia", IATA: "I8" },
  { Name: "Indonesia AirAsia", Country: "Indonesia", IATA: "QZ" },
  { Name: "Indonesia AirAsia X", Country: "Indonesia", IATA: "XT" },
  { Name: "Insel Air", Country: "Cura�ao", IATA: "7I" },
  { Name: "Insel Air Aruba", Country: "Cura�ao", IATA: "8I" },
  { Name: "Int'Air �les", Country: "Comoros", IATA: "I7" },
  {
    Name: "InterCaribbean Airways",
    Country: "Turks and Caicos Islands",
    IATA: "JY",
  },
  { Name: "Interjet-ABC", Country: "Mexico", IATA: "4O" },
  { Name: "International AirLink", Country: "Jamaica", IATA: "I4" },
  { Name: "IrAero", Country: "Russian Federation", IATA: "IO" },
  { Name: "Iran Air", Country: "Iran", IATA: "IR" },
  { Name: "Iran Airtour", Country: "Iran", IATA: "B9" },
  { Name: "Iran Aseman Airlines", Country: "Iran", IATA: "EP" },
  { Name: "Iraqi Airways", Country: "Iraq", IATA: "IA" },
  { Name: "Irtysh Air", Country: "Kazakhstan", IATA: "IH" },
  { Name: "Island Air Express", Country: "United States", IATA: "I4" },
  { Name: "Island Airlines Hawaii", Country: "United States", IATA: "WP" },
  { Name: "Israir Airlines", Country: "Israel", IATA: "6H" },
  { Name: "IZair", Country: "Turkey", IATA: "4I" },
  { Name: "Izhavia", Country: "Russian Federation", IATA: "I8" },
  { Name: "J-Air", Country: "Japan", IATA: "XM" },
  { Name: "Jambojet", Country: "Kenya", IATA: "JM" },
  { Name: "Japan Air Commuter", Country: "Japan", IATA: "JC" },
  { Name: "Japan Airlines", Country: "Japan", IATA: "JL" },
  { Name: "Japan TransOcean Air", Country: "Japan", IATA: "NU" },
  { Name: "Jazeera Airways", Country: "Kuwait", IATA: "J9" },
  { Name: "Jazz", Country: "Canada", IATA: "QK" },
  { Name: "JC International Airlines", Country: "Cambodia", IATA: "QD" },
  { Name: "Jeju Air", Country: "South Korea", IATA: "7C" },
  { Name: "Jet Airways", Country: "India", IATA: "9W" },
  { Name: "Jet Asia Airways", Country: "Thailand", IATA: "JF" },
  { Name: "Jet Time", Country: "Denmark", IATA: "JO" },
  { Name: "Jet2", Country: "United Kingdom", IATA: "LS" },
  { Name: "Jet2 holidays", Country: "United Kingdom", IATA: "LS" },
  { Name: "Jetairfly", Country: "Belgium", IATA: "TB" },
  { Name: "JetBlue Airways", Country: "United States", IATA: "B6" },
  { Name: "Jetconnect", Country: "New Zealand", IATA: "QF" },
  { Name: "Jetgo", Country: "Australia", IATA: "JG" },
  { Name: "JetLite", Country: "India", IATA: "S2" },
  { Name: "JetSmart", Country: "Chile", IATA: "JA" },
  { Name: "Jetstar", Country: "Australia", IATA: "JQ" },
  { Name: "Jetstar Asia", Country: "Singapore", IATA: "3K" },
  { Name: "Jetstar Japan", Country: "Japan", IATA: "GK" },
  { Name: "Jetstar Pacific", Country: "Vietnam", IATA: "BL" },
  { Name: "Jetways Airlines", Country: "Kenya", IATA: "WU" },
  { Name: "Jiangxi Air", Country: "China", IATA: "RY" },
  { Name: "Jin Air", Country: "South Korea", IATA: "LJ" },
  {
    Name: "Job Air - Central Connect Airlines",
    Country: "Czech Republic",
    IATA: "3B",
  },
  { Name: "Joon", Country: "France", IATA: "JN" },
  { Name: "Jordan Aviation", Country: "Jordan", IATA: "R5" },
  { Name: "Joy Air", Country: "China", IATA: "JR" },
  { Name: "Jubba Airways", Country: "Somalia", IATA: "3J" },
  { Name: "Jubba Airways Kenya", Country: "Kenya", IATA: "JZ" },
  { Name: "Juneyao Airlines", Country: "China", IATA: "HO" },
  { Name: "K-Mile Air", Country: "Thailand", IATA: "8K" },
  { Name: "Kabo Air", Country: "Nigeria", IATA: "N9" },
  { Name: "Kalitta Air", Country: "United States", IATA: "K4" },
  { Name: "Kalitta Charters", Country: "United States", IATA: "K9" },
  { Name: "Kam Air", Country: "Afghanistan", IATA: "RQ" },
  { Name: "Kan Air", Country: "Thailand", IATA: "K8" },
  { Name: "Karthago Airlines", Country: "Tunisia", IATA: "5R" },
  { Name: "Karun Airlines", Country: "Iran", IATA: "NV" },
  { Name: "Kaya Airlines", Country: "Mozambique", IATA: "IK" },
  { Name: "KC International Airlines", Country: "Cambodia", IATA: "3Q" },
  { Name: "Kenmore Air", Country: "United States", IATA: "M5" },
  { Name: "Kenn Borek Air", Country: "Canada", IATA: "4K" },
  { Name: "Kenya Airways", Country: "Kenya", IATA: "KQ" },
  { Name: "KF Cargo", Country: "Canada", IATA: "FK" },
  { Name: "Khors Air", Country: "Ukraine", IATA: "KO" },
  { Name: "Kish Air", Country: "Iran", IATA: "Y9" },
  { Name: "KLM Cityhopper", Country: "Netherlands", IATA: "WA" },
  { Name: "KLM Royal Dutch Airlines", Country: "Netherlands", IATA: "KL" },
  { Name: "Komiaviatrans", Country: "Russian Federation", IATA: "KO" },
  { Name: "Korean Air", Country: "South Korea", IATA: "KE" },
  { Name: "Korean Air Cargo", Country: "South Korea", IATA: "KE" },
  { Name: "KrasAvia", Country: "Russian Federation", IATA: "KI" },
  { Name: "Kulula.com", Country: "South Africa", IATA: "MN" },
  { Name: "Kunming Airlines", Country: "China", IATA: "KY" },
  { Name: "Kuwait Airways", Country: "Kuwait", IATA: "KU" },
  { Name: "Kyrgyzstan Air Company", Country: "Kyrgyzstan", IATA: "QH" },
  { Name: "La Compagnie", Country: "France", IATA: "B0" },
  { Name: "LADE - Lineas A�reas del Estado", Country: "Argentina", IATA: "5U" },
  { Name: "LAM Mozambique Airlines", Country: "Mozambique", IATA: "TM" },
  { Name: "Lanmei Airlines", Country: "Cambodia", IATA: "LQ" },
  { Name: "Lao Airlines", Country: "Laos", IATA: "QV" },
  { Name: "Lao Skyway", Country: "Laos", IATA: "LK" },
  { Name: "Laparkan Airways", Country: "Guyana", IATA: "LE" },
  {
    Name: "LASER-Linea A�rea de Servicio Ejecutivo Regional",
    Country: "Venezuela",
    IATA: "QL",
  },
  { Name: "LATAM Argentina", Country: "Argentina", IATA: "4M" },
  { Name: "LATAM Brasil", Country: "Brazil", IATA: "JJ" },
  { Name: "LATAM Cargo Brasil", Country: "Brazil", IATA: "M3" },
  { Name: "LATAM Cargo Chile", Country: "Chile", IATA: "UC" },
  { Name: "LATAM Cargo Colombia", Country: "Colombia", IATA: "L7" },
  { Name: "LATAM Cargo Mexico", Country: "Mexico", IATA: "M7" },
  { Name: "LATAM Chile", Country: "Chile", IATA: "LA" },
  { Name: "LATAM Colombia", Country: "Colombia", IATA: "4C" },
  { Name: "LATAM Ecuador", Country: "Ecuador", IATA: "XL" },
  { Name: "LATAM Express", Country: "Chile", IATA: "LU" },
  { Name: "LATAM Paraquay", Country: "Paraguay", IATA: "PZ" },
  { Name: "LATAM Per�", Country: "Peru", IATA: "LP" },
  { Name: "Laudamotion", Country: "Austria", IATA: "OE" },
  { Name: "LC Per�", Country: "Peru", IATA: "W4" },
  { Name: "Level", Country: "France", IATA: "LV" },
  { Name: "LIAT", Country: "Antigua", IATA: "LI" },
  { Name: "Libyan Airlines", Country: "Libya", IATA: "LN" },
  { Name: "Libyan Wings", Country: "Libya", IATA: "YL" },
  { Name: "Lineas A�reas Suramericanas", Country: "Colombia", IATA: "4L" },
  { Name: "Lion Air", Country: "Indonesia", IATA: "JT" },
  { Name: "LJ Air", Country: "China", IATA: "LT" },
  { Name: "Loganair", Country: "United Kingdom", IATA: "LM" },
  { Name: "Longhao Airlines", Country: "China", IATA: "GI" },
  { Name: "Loong Air", Country: "China", IATA: "GJ" },
  { Name: "LOT Polish Airlines", Country: "Poland", IATA: "LO" },
  { Name: "Lucky Air", Country: "China", IATA: "8L" },
  { Name: "Luftfahrtgesellschaft Walter", Country: "Germany", IATA: "HE" },
  { Name: "Lufthansa", Country: "Germany", IATA: "LH" },
  { Name: "Lufthansa Cargo", Country: "Germany", IATA: "LH" },
  { Name: "Lufthansa CityLine", Country: "Germany", IATA: "CL" },
  { Name: "Lufttransport", Country: "Norway", IATA: "L5" },
  { Name: "Luxair", Country: "Luxembourg", IATA: "LG" },
  { Name: "Lynden Air Cargo", Country: "United States", IATA: "L2" },
  { Name: "Magnicharters", Country: "Mexico", IATA: "GM" },
  { Name: "Mahan Air", Country: "Iran", IATA: "W5" },
  { Name: "Malawian Airlines", Country: "Malawi", IATA: "3W" },
  { Name: "Malaysia Airlines", Country: "Malaysia", IATA: "MH" },
  { Name: "Maldivian", Country: "Maldives", IATA: "Q2" },
  { Name: "Maleth-Aero", Country: "Malta", IATA: "DB" },
  { Name: "Malindo Air", Country: "Malaysia", IATA: "OD" },
  { Name: "Mandarin Airlines", Country: "Taiwan", IATA: "AE" },
  { Name: "Mango", Country: "South Africa", IATA: "JE" },
  { Name: "Mann Yatanarpon Airlines", Country: "Myanmar", IATA: "7Y" },
  { Name: "MAP Linhas A�reas", Country: "Brazil", IATA: "M1" },
  { Name: "Martinair", Country: "Netherlands", IATA: "MP" },
  { Name: "MASwings", Country: "Malaysia", IATA: "MH" },
  { Name: "Mauritania Airlines", Country: "Mauritania", IATA: "L6" },
  { Name: "Max Air", Country: "Nigeria", IATA: "VM" },
  { Name: "Maya Island Air", Country: "Belize", IATA: "2M" },
  { Name: "MAYAir", Country: "Mexico", IATA: "7M" },
  { Name: "Med-View Airlines", Country: "Nigeria", IATA: "VL" },
  { Name: "MenaJet", Country: "Lebanon", IATA: "IM" },
  { Name: "Mercury World Cargo", Country: "United States", IATA: "4X" },
  { Name: "Mesa Airlines", Country: "United States", IATA: "YV" },
  { Name: "MHS Aviation", Country: "Germany", IATA: "M2" },
  { Name: "Miami Air International", Country: "United States", IATA: "LL" },
  { Name: "MIAT-Mongolian Airlines", Country: "Mongolia", IATA: "OM" },
  { Name: "Middle East Airlines", Country: "Lebanon", IATA: "ME" },
  { Name: "Midwest Airlines (Egypt)", Country: "Egypt", IATA: "MY" },
  { Name: "Mistral Air", Country: "Italy", IATA: "M4" },
  { Name: "MNG Airlines", Country: "Turkey", IATA: "MB" },
  { Name: "Modern Logistics", Country: "Brazil", IATA: "WD" },
  { Name: "Mokulele Airlines", Country: "United States", IATA: "MW" },
  { Name: "Monacair", Country: "Monaco", IATA: "QM" },
  { Name: "Montenegro Airlines", Country: "Montenegro", IATA: "YM" },
  { Name: "Mount Cook Airline", Country: "New Zealand", IATA: "NM" },
  { Name: "My Indo Airlines", Country: "Indonesia", IATA: "2Y" },
  { Name: "My Jet Xpress Airlines", Country: "Malaysia", IATA: "N7" },
  { Name: "Myanma Airways", Country: "Myanmar", IATA: "UB" },
  { Name: "Myanmar Airways International", Country: "Myanmar", IATA: "8M" },
  { Name: "MyWay Airlines", Country: "Georgia", IATA: "MJ" },
  { Name: "NAM Air", Country: "Indonesia", IATA: "IN" },
  { Name: "Nasair", Country: "Eritrea", IATA: "UE" },
  { Name: "National Airlines", Country: "United States", IATA: "N8" },
  { Name: "Nature Air", Country: "Costa Rica", IATA: "5C" },
  { Name: "Nauru Airlines", Country: "Nauru", IATA: "ON" },
  { Name: "NAYSA", Country: "Spain", IATA: "ZN" },
  { Name: "Neos", Country: "Italy", IATA: "NO" },
  { Name: "Nepal Airlines", Country: "Nepal", IATA: "RA" },
  { Name: "Nesma Airlines", Country: "Egypt", IATA: "NE" },
  {
    Name: "Nesma Airlines (Saudi Arabia)",
    Country: "Saudi Arabia",
    IATA: "NA",
  },
  { Name: "NetJets", Country: "United States", IATA: "1I" },
  { Name: "NetJets Europe", Country: "Portugal", IATA: "1I" },
  { Name: "New England Airlines", Country: "United States", IATA: "EJ" },
  { Name: "New Gen Airways", Country: "Russian Federation", IATA: "E3" },
  { Name: "Nextjet", Country: "Sweden", IATA: "2N" },
  { Name: "Niger Airlines", Country: "Niger", IATA: "6N" },
  { Name: "Niki", Country: "Austria", IATA: "HG" },
  { Name: "Nile Air", Country: "Egypt", IATA: "NP" },
  { Name: "Nippon Cargo Airlines (NCA)", Country: "Japan", IATA: "KZ" },
  { Name: "Nok Air", Country: "Thailand", IATA: "DD" },
  { Name: "NokScoot", Country: "Thailand", IATA: "XW" },
  { Name: "Nolinor Aviation", Country: "Canada", IATA: "N5" },
  {
    Name: "Nordavia Regional Airlines",
    Country: "Russian Federation",
    IATA: "5N",
  },
  { Name: "Nordic Regional Airlines", Country: "Norway", IATA: "N7" },
  { Name: "Nordica", Country: "Estonia", IATA: "EE" },
  { Name: "NordStar", Country: "Russian Federation", IATA: "Y7" },
  { Name: "Nordwind Airlines", Country: "Russian Federation", IATA: "N4" },
  { Name: "North-Wright Airways Ltd.", Country: "Canada", IATA: "HW" },
  { Name: "Northern Air Cargo (NAC)", Country: "United States", IATA: "NC" },
  { Name: "Northwestern Air", Country: "Canada", IATA: "J3" },
  { Name: "Norwegian Air Argentina", Country: "Argentina", IATA: "DN" },
  { Name: "Norwegian Air International", Country: "Norway", IATA: "D8" },
  { Name: "Norwegian Air Shuttle", Country: "Norway", IATA: "DY" },
  { Name: "Norwegian Air UK", Country: "Norway", IATA: "DI" },
  { Name: "Norwegian Long Haul", Country: "Norway", IATA: "DU" },
  { Name: "Nouvelair", Country: "Tunisia", IATA: "BJ" },
  { Name: "Nova Airways", Country: "Sudan", IATA: "O9" },
  { Name: "Novair", Country: "Sweden", IATA: "N9" },
  { Name: "Novoair", Country: "Bangladesh", IATA: "VQ" },
  { Name: "Ohana by Hawaiian", Country: "United States", IATA: "HA" },
  { Name: "Okay Airways", Country: "China", IATA: "BK" },
  { Name: "Olympic Air", Country: "Greece", IATA: "OA" },
  { Name: "Oman Air", Country: "Oman", IATA: "WY" },
  { Name: "Omni Air International", Country: "United States", IATA: "OY" },
  { Name: "One Airlines", Country: "Chile", IATA: "IP" },
  { Name: "OneJet", Country: "United States", IATA: "J1" },
  { Name: "Onur Air", Country: "Turkey", IATA: "8Q" },
  { Name: "Orange2Fly", Country: "Greece", IATA: "O4" },
  { Name: "Orbest", Country: "Portugal", IATA: "6O" },
  { Name: "Orenair", Country: "Russian Federation", IATA: "R2" },
  { Name: "Orenburzhye", Country: "Russian Federation", IATA: "O7" },
  { Name: "Orient Thai Airlines", Country: "Thailand", IATA: "OX" },
  { Name: "Oriental Air Bridge", Country: "Japan", IATA: "OC" },
  { Name: "Originair", Country: "New Zealand", IATA: "OG" },
  { Name: "Overland Airways", Country: "Nigeria", IATA: "OF" },
  { Name: "Pacific Air Express", Country: "Australia", IATA: "PE" },
  { Name: "Pacific Airways Inc", Country: "United States", IATA: "3F" },
  { Name: "Pacific Coastal Airlines", Country: "Canada", IATA: "8P" },
  { Name: "Pacific Wings", Country: "United States", IATA: "LW" },
  {
    Name: "Pakistan International Airlines (PIA)",
    Country: "Pakistan",
    IATA: "PK",
  },
  { Name: "PAL Airlines", Country: "Canada", IATA: "PB" },
  { Name: "PAL Express", Country: "Philippines", IATA: "2P" },
  {
    Name: "Palestinian Airlines",
    Country: "Palestinian Authority",
    IATA: "PF",
  },
  { Name: "Pan Pacific Airlines", Country: "Philippines", IATA: "8Y" },
  { Name: "Pascan Aviation Inc.", Country: "Canada", IATA: "P6" },
  { Name: "Passaredo Linhas A�reas", Country: "Brazil", IATA: "2Z" },
  { Name: "Peach", Country: "Japan", IATA: "MM" },
  { Name: "Pegas Fly", Country: "Russian Federation", IATA: "EO" },
  { Name: "Pegasus Airlines", Country: "Turkey", IATA: "PC" },
  { Name: "Pelita Air", Country: "Indonesia", IATA: "6D" },
  { Name: "PenAir", Country: "United States", IATA: "KS" },
  { Name: "People's Vienna Line", Country: "Austria", IATA: "PE" },
  { Name: "Perimeter Airlines", Country: "Canada", IATA: "YP" },
  { Name: "Peruvian Airlines", Country: "Peru", IATA: "P9" },
  { Name: "Philippine Airlines", Country: "Philippines", IATA: "PR" },
  { Name: "Philippines AirAsia", Country: "Philippines", IATA: "Z2" },
  { Name: "Piedmont Airlines", Country: "United States", IATA: "PT" },
  { Name: "Plus Ultra L�neas A�reas", Country: "Spain", IATA: "PU" },
  { Name: "PNG Air", Country: "Papua New Guinea", IATA: "CG" },
  { Name: "Podeba", Country: "Russian Federation", IATA: "DP" },
  { Name: "Polar Air Cargo", Country: "United States", IATA: "PO" },
  { Name: "Polar Airlines", Country: "Russian Federation", IATA: "PI" },
  { Name: "Porter Airlines", Country: "Canada", IATA: "PD" },
  { Name: "Portug�lia", Country: "Portugal", IATA: "NI" },
  { Name: "Precision Air Services", Country: "Tanzania", IATA: "PW" },
  { Name: "Premier Trans Aire", Country: "United States", IATA: "3X" },
  { Name: "Premium Jet", Country: "Switzerland", IATA: "0J" },
  { Name: "Primera Air", Country: "Iceland", IATA: "PF" },
  { Name: "Primera Air Nordic", Country: "Iceland", IATA: "6F" },
  { Name: "PrivatAir", Country: "Switzerland", IATA: "PV" },
  { Name: "Private Wings", Country: "Germany", IATA: "8W" },
  { Name: "Privilege Style", Country: "Spain", IATA: "P6" },
  { Name: "Proflight Commuter Services", Country: "Zambia", IATA: "P0" },
  { Name: "PSA Airlines", Country: "United States", IATA: "OH" },
  { Name: "PublicCharters.com", Country: "United States", IATA: "P1" },
  { Name: "Qantas Airways", Country: "Australia", IATA: "QF" },
  { Name: "Qantas Freight", Country: "Australia", IATA: "QF" },
  { Name: "QantasLink", Country: "Australia", IATA: "QF" },
  { Name: "Qatar Airways", Country: "Qatar", IATA: "QR" },
  { Name: "Qazaq Air", Country: "Kazakhstan", IATA: "IQ" },
  { Name: "Qeshm Air", Country: "Iran", IATA: "QB" },
  { Name: "Qingdao Airlines", Country: "China", IATA: "QW" },
  { Name: "Quikjet Airlines", Country: "India", IATA: "QO" },
  { Name: "R Airlines", Country: "Thailand", IATA: "RK" },
  { Name: "RAK Airways", Country: "United Arab Emirates", IATA: "RT" },
  { Name: "Ravn Alaska", Country: "United States", IATA: "7H" },
  { Name: "Ravn Connect", Country: "United States", IATA: "H6" },
  { Name: "Raya Airways", Country: "Malaysia", IATA: "TH" },
  { Name: "Rayani Air", Country: "Malaysia", IATA: "RN" },
  { Name: "Red Wings Airlines", Country: "Russian Federation", IATA: "WZ" },
  { Name: "Regent Airways", Country: "Bangladesh", IATA: "RX" },
  { Name: "Regional Air Services", Country: "Tanzania", IATA: "8N" },
  { Name: "Regional Cargo", Country: "Mexico", IATA: "F2" },
  {
    Name: "R�gional Compagnie A�rienne Europ�enne",
    Country: "France",
    IATA: "YS",
  },
  { Name: "Regional Express", Country: "Australia", IATA: "ZL" },
  { Name: "Republic Airlines", Country: "United States", IATA: "YX" },
  { Name: "Rossiya", Country: "Russian Federation", IATA: "FV" },
  { Name: "Rotana Jet", Country: "United Arab Emirates", IATA: "RG" },
  { Name: "Royal Air Maroc", Country: "Morocco", IATA: "AT" },
  { Name: "Royal Brunei Airlines", Country: "Brunei Darussalam", IATA: "BI" },
  { Name: "Royal Flight", Country: "Russian Federation", IATA: "RL" },
  { Name: "Royal Jordanian Airlines", Country: "Jordan", IATA: "RJ" },
  { Name: "Royal Wings", Country: "Jordan", IATA: "RY" },
  { Name: "Ruili Airlines", Country: "China", IATA: "DR" },
  { Name: "RusLine", Country: "Russian Federation", IATA: "7R" },
  { Name: "RUTACA Airlines", Country: "Venezuela", IATA: "5R" },
  { Name: "RwandAir", Country: "Rwanda", IATA: "WB" },
  { Name: "Ryan Air Services", Country: "United States", IATA: "7S" },
  { Name: "Ryanair", Country: "Ireland", IATA: "FR" },
  { Name: "Ryanair Sun", Country: "Poland", IATA: "RR" },
  { Name: "S7 Airlines", Country: "Russian Federation", IATA: "S7" },
  { Name: "SAEREO", Country: "Ecuador", IATA: "MZ" },
  { Name: "Safair", Country: "South Africa", IATA: "FA" },
  { Name: "Safari Link", Country: "Kenya", IATA: "F2" },
  { Name: "Safe Air Company", Country: "Kenya", IATA: "K3" },
  { Name: "Safi Airways", Country: "Afghanistan", IATA: "4Q" },
  { Name: "SalamAir", Country: "Oman", IATA: "OV" },
  { Name: "SALSA d'Haiti", Country: "Haiti", IATA: "SO" },
  { Name: "Samoa Airways", Country: "Western Samoa", IATA: "OL" },
  { Name: "SANSA Airlines", Country: "Costa Rica", IATA: "RZ" },
  {
    Name: "SAS Scandinavian Airlines",
    Country: "Norway/Denmark/Sweden",
    IATA: "SK",
  },
  { Name: "SATA Air A�ores", Country: "Portugal", IATA: "SP" },
  { Name: "SATENA", Country: "Colombia", IATA: "9R" },
  { Name: "Saudia", Country: "Saudi Arabia", IATA: "SV" },
  { Name: "SaudiGulf Airlines", Country: "Saudi Arabia", IATA: "6S" },
  { Name: "SCAT Airlines", Country: "Kazakhstan", IATA: "DV" },
  { Name: "Scenic Airlines", Country: "United States", IATA: "YR" },
  { Name: "Scoot", Country: "Singapore", IATA: "TR" },
  { Name: "Seaborne Airlines", Country: "US Virgin Islands", IATA: "BB" },
  { Name: "Sepehran Airlines", Country: "Iran", IATA: "IS" },
  { Name: "SereneAir", Country: "Pakistan", IATA: "ER" },
  { Name: "Servant Air", Country: "United States", IATA: "8D" },
  { Name: "Severstal Air Company", Country: "Russian Federation", IATA: "D2" },
  { Name: "SF Airlines", Country: "China", IATA: "O3" },
  { Name: "Shaheen Air International", Country: "Pakistan", IATA: "NL" },
  { Name: "Shandong Airlines", Country: "China", IATA: "SC" },
  { Name: "Shanghai Airlines", Country: "China", IATA: "FM" },
  { Name: "Sharp Airlines", Country: "Australia", IATA: "SH" },
  { Name: "Shenzhen Airlines", Country: "China", IATA: "ZH" },
  { Name: "Shree Airlines", Country: "Nepal", IATA: "N9" },
  { Name: "Siam Air", Country: "Thailand", IATA: "O8" },
  { Name: "SiAvia", Country: "Slovenia", IATA: "KT" },
  { Name: "Sichuan Airlines", Country: "China", IATA: "3U" },
  { Name: "Sierra Pacific Airlines", Country: "United States", IATA: "SI" },
  { Name: "Silk Way Airlines", Country: "Azerbaijan", IATA: "ZP" },
  { Name: "Silk Way West Airlines", Country: "Azerbaijan", IATA: "7L" },
  { Name: "SilkAir", Country: "Singapore", IATA: "MI" },
  { Name: "Silver Airways", Country: "United States", IATA: "3M" },
  { Name: "Singapore Airlines", Country: "Singapore", IATA: "SQ" },
  { Name: "Singapore Airlines Cargo", Country: "Singapore", IATA: "SQ" },
  { Name: "Skippers Aviation", Country: "Australia", IATA: "HK" },
  { Name: "Sky Airline", Country: "Chile", IATA: "H2" },
  { Name: "Sky Angkor Airlines", Country: "Cambodia", IATA: "ZA" },
  { Name: "Sky Express", Country: "Greece", IATA: "GQ" },
  { Name: "Sky Gabon", Country: "Gabon", IATA: "GV" },
  { Name: "Sky Gates Airlines", Country: "Russian Federation", IATA: "U3" },
  { Name: "Sky Lease Cargo", Country: "United States", IATA: "GG" },
  { Name: "Sky Prime Aviation", Country: "Saudi Arabia", IATA: "UY" },
  { Name: "Sky Regional Airlines", Country: "Canada", IATA: "KV" },
  { Name: "SkyBahamas", Country: "Bahamas", IATA: "Q7" },
  { Name: "SkyJet Airlines", Country: "Philippines", IATA: "M8" },
  { Name: "Skymark Airlines", Country: "Japan", IATA: "BC" },
  { Name: "SkyTaxi", Country: "Poland", IATA: "TE" },
  { Name: "Skytrans", Country: "Australia", IATA: "QN" },
  { Name: "SkyUp", Country: "Ukraine", IATA: "PQ" },
  { Name: "Skyward Express", Country: "Kenya", IATA: "OW" },
  { Name: "SkyWest Airlines", Country: "United States", IATA: "OO" },
  { Name: "SkyWork Airlines", Country: "Switzerland", IATA: "SX" },
  { Name: "Small Planet Airlines", Country: "Lithuania", IATA: "S5" },
  { Name: "Small Planet Airlines Poland", Country: "Poland", IATA: "P7" },
  { Name: "Smart Wings", Country: "Czech Republic", IATA: "QS" },
  { Name: "Smartlynx Airlines", Country: "Latvia", IATA: "6Y" },
  { Name: "Smokey Bay Air", Country: "United States", IATA: "2E" },
  { Name: "Solar Cargo", Country: "Venezuela", IATA: "4S" },
  { Name: "Solaseed Air", Country: "Japan", IATA: "6J" },
  { Name: "Solomon Airlines", Country: "Solomon Islands", IATA: "IE" },
  { Name: "Somon Air", Country: "Tajikistan", IATA: "SZ" },
  { Name: "Sounds Air", Country: "New Zealand", IATA: "S8" },
  { Name: "South African Airways", Country: "South Africa", IATA: "SA" },
  {
    Name: "South African Express Airways",
    Country: "South Africa",
    IATA: "XZ",
  },
  { Name: "Southern Air", Country: "United States", IATA: "9S" },
  { Name: "Southern Air Charter", Country: "Bahamas", IATA: "PL" },
  { Name: "Southern Airways Express", Country: "United States", IATA: "9X" },
  { Name: "Southwest Airlines", Country: "United States", IATA: "WN" },
  { Name: "SpiceJet", Country: "India", IATA: "SG" },
  { Name: "Spirit Airlines", Country: "United States", IATA: "NK" },
  { Name: "Spring Airlines", Country: "China", IATA: "9C" },
  { Name: "Spring Airlines Japan", Country: "Japan", IATA: "IJ" },
  { Name: "SprintAir", Country: "Poland", IATA: "P8" },
  { Name: "SriLankan Airlines", Country: "Sri Lanka", IATA: "UL" },
  { Name: "Sriwijaya Air", Country: "Indonesia", IATA: "SJ" },
  { Name: "St Barth Commuter", Country: "Saint-Barth�lemy", IATA: "PV" },
  { Name: "Star East Airlines", Country: "Romania", IATA: "4R" },
  { Name: "Star Per�", Country: "Peru", IATA: "2I" },
  { Name: "Starbow Airlines", Country: "Ghana", IATA: "S9" },
  { Name: "StarFlyer", Country: "Japan", IATA: "7G" },
  { Name: "Starlight Airlines", Country: "Kenya", IATA: "QP" },
  { Name: "Stobart Air", Country: "Ireland", IATA: "RE" },
  { Name: "STP Airways", Country: "S�o Tom� and Pr�ncipe", IATA: "8F" },
  { Name: "Sudan Airways", Country: "Sudan", IATA: "SD" },
  { Name: "Sun Air", Country: "Sudan", IATA: "S6" },
  { Name: "Sun Air of Scandinavia", Country: "Denmark", IATA: "EZ" },
  { Name: "Sun Country Airlines", Country: "United States", IATA: "SY" },
  { Name: "Sundair", Country: "Germany", IATA: "SR" },
  { Name: "SunExpress", Country: "Turkey", IATA: "XQ" },
  { Name: "SunExpress Deutschland", Country: "Germany", IATA: "XG" },
  { Name: "Sunrise Airways", Country: "Haiti", IATA: "S6" },
  { Name: "Sunwing Airlines", Country: "Canada", IATA: "WG" },
  { Name: "Surinam Airways", Country: "Suriname", IATA: "PY" },
  { Name: "Susi Air", Country: "Indonesia", IATA: "SI" },
  { Name: "Swift Air", Country: "United States", IATA: "WQ" },
  { Name: "Swiftair", Country: "Spain", IATA: "WT" },
  { Name: "Swiss Global Air Lines", Country: "Switzerland", IATA: "LZ" },
  { Name: "Swiss International Air Lines", Country: "Switzerland", IATA: "LX" },
  { Name: "Swoop", Country: "Canada", IATA: "WO" },
  { Name: "Sylt Air", Country: "Germany", IATA: "7E" },
  { Name: "SyrianAir", Country: "Syria", IATA: "RB" },
  { Name: "T'way Air", Country: "South Korea", IATA: "TW" },
  { Name: "TAAG Angola Airlines", Country: "Angola", IATA: "DT" },
  { Name: "Taban Air", Country: "Iran", IATA: "HH" },
  { Name: "Tailwind Airlines", Country: "Turkey", IATA: "TI" },
  { Name: "Tajik Air", Country: "Tajikistan", IATA: "7J" },
  { Name: "TAME Linea A�rea del Ecuador", Country: "Ecuador", IATA: "EQ" },
  { Name: "Tanana Air Service", Country: "United States", IATA: "4E" },
  { Name: "Tandem Aero", Country: "Moldova", IATA: "TQ" },
  { Name: "TAP Express", Country: "Portugal", IATA: "NI" },
  { Name: "TAP Portugal", Country: "Portugal", IATA: "TP" },
  { Name: "Taquan Air", Country: "United States", IATA: "K3" },
  { Name: "TAR Aerol�neas", Country: "Mexico", IATA: "YQ" },
  { Name: "Tarco Air", Country: "Sudan", IATA: "3T" },
  { Name: "TAROM Romanian Air Transport", Country: "Romania", IATA: "RO" },
  { Name: "Tasman Cargo Airlines", Country: "Australia", IATA: "HJ" },
  { Name: "Tassili Airlines", Country: "Algeria", IATA: "SF" },
  { Name: "Thai AirAsia", Country: "Thailand", IATA: "FD" },
  { Name: "Thai AirAsia X", Country: "Thailand", IATA: "XJ" },
  { Name: "Thai Airways International", Country: "Thailand", IATA: "TG" },
  { Name: "Thai Lion Air", Country: "Thailand", IATA: "SL" },
  { Name: "Thai Smile", Country: "Thailand", IATA: "WE" },
  { Name: "Thai Vietjet Air", Country: "Thailand", IATA: "VZ" },
  { Name: "Thomas Cook Airlines", Country: "United Kingdom", IATA: "MT" },
  { Name: "Thomas Cook Airlines Scandinavia", Country: "Denmark", IATA: "DK" },
  { Name: "Tianjin Airlines", Country: "China", IATA: "GS" },
  { Name: "Tibet Airlines", Country: "China", IATA: "TV" },
  { Name: "Tigerair", Country: "Singapore", IATA: "TR" },
  { Name: "Tigerair Australia", Country: "Australia", IATA: "TT" },
  { Name: "Tigerair Taiwan", Country: "Taiwan", IATA: "IT" },
  { Name: "Titan Airways", Country: "United Kingdom", IATA: "ZT" },
  { Name: "Touma� Air Tchad", Country: "Chad", IATA: "9D" },
  { Name: "Trade Air", Country: "Croatia", IATA: "C3" },
  { Name: "Tradewind Aviation", Country: "United States", IATA: "TJ" },
  { Name: "Trans Air Benin", Country: "Benin", IATA: "N4" },
  { Name: "Trans Air Congo", Country: "Republic of Congo", IATA: "Q8" },
  { Name: "Trans Maldivian Airways", Country: "Maldives", IATA: "M8" },
  { Name: "Trans States Airlines", Country: "United States", IATA: "AX" },
  { Name: "Transaero Airlines", Country: "Russian Federation", IATA: "UN" },
  {
    Name: "Transafrik International",
    Country: "S�o Tom� and Pr�ncipe",
    IATA: "PQ",
  },
  { Name: "Transair", Country: "United States", IATA: "KH" },
  { Name: "Transavia", Country: "Netherlands", IATA: "HV" },
  { Name: "Transavia France", Country: "France", IATA: "TO" },
  { Name: "Transcarga Intl Airways", Country: "Venezuela", IATA: "T9" },
  { Name: "TransNusa", Country: "Indonesia", IATA: "8B" },
  {
    Name: "Transportes A�reos Guatemaltecos",
    Country: "Guatemala",
    IATA: "5U",
  },
  { Name: "Travel Service Airlines", Country: "Czech Republic", IATA: "QS" },
  { Name: "Travel Service Hungary", Country: "Hungary", IATA: "7O" },
  { Name: "Travel Service Polska", Country: "Poland", IATA: "3Z" },
  { Name: "Travel Service Slovakia", Country: "Slovakia", IATA: "6D" },
  { Name: "Tri-MG Airlines", Country: "Indonesia", IATA: "GM" },
  { Name: "Trigana Air Service", Country: "Indonesia", IATA: "IL" },
  { Name: "TRIP Linhas A�reas", Country: "Brazil", IATA: "T4" },
  { Name: "Tropic Air", Country: "Belize", IATA: "9N" },
  { Name: "TruJet", Country: "India", IATA: "2T" },
  { Name: "TUI Airways", Country: "United Kingdom", IATA: "BY" },
  { Name: "TUI fly Netherlands", Country: "Netherlands", IATA: "OR" },
  { Name: "TUIfly", Country: "Germany", IATA: "X3" },
  { Name: "TUIfly Nordic", Country: "Sweden", IATA: "6B" },
  { Name: "Tunisair", Country: "Tunisia", IATA: "TU" },
  { Name: "Tunisair Express", Country: "Tunisia", IATA: "UG" },
  { Name: "Turkish Airlines", Country: "Turkey", IATA: "TK" },
  { Name: "Turkmenistan Airlines", Country: "Turkmenistan", IATA: "T5" },
  { Name: "Turpial Airlines", Country: "Venezuela", IATA: "V9" },
  { Name: "Tus Airways", Country: "Cyprus", IATA: "U8" },
  { Name: "Twin Jet", Country: "France", IATA: "T7" },
  { Name: "Ukraine International Airlines", Country: "Ukraine", IATA: "PS" },
  { Name: "Ulendo Airlink", Country: "Malawi", IATA: "7Q" },
  { Name: "ULS Airlines Cargo", Country: "Turkey", IATA: "GO" },
  { Name: "UM Air", Country: "Ukraine", IATA: "UF" },
  { Name: "UNI Airways", Country: "Taiwan", IATA: "B7" },
  { Name: "Uni-top Airlines", Country: "China", IATA: "UW" },
  { Name: "United Airlines", Country: "United States", IATA: "UA" },
  { Name: "United Airways Bangladesh", Country: "Bangladesh", IATA: "4H" },
  { Name: "UniWorld Air Cargo", Country: "Kenya", IATA: "U7" },
  { Name: "UPS Airlines", Country: "United States", IATA: "5X" },
  { Name: "Ural Airlines", Country: "Russian Federation", IATA: "U6" },
  { Name: "Urumqi Air", Country: "China", IATA: "UQ" },
  { Name: "US Airways", Country: "United States", IATA: "US" },
  { Name: "US-Bangla Airlines", Country: "Bangladesh", IATA: "BS" },
  { Name: "USA Jet Airlines", Country: "United States", IATA: "UJ" },
  { Name: "UTair Aviation", Country: "Russian Federation", IATA: "UT" },
  { Name: "UVT Aero", Country: "Russian Federation", IATA: "RT" },
  { Name: "Uzbekistan Airways", Country: "Uzbekistan", IATA: "HY" },
  { Name: "V Air", Country: "Taiwan", IATA: "ZV" },
  { Name: "Vanilla Air", Country: "Japan", IATA: "JW" },
  { Name: "Veca Airlines", Country: "El Salvador", IATA: "VU" },
  { Name: "Venezolana", Country: "Venezuela", IATA: "VN" },
  { Name: "Vensecar Internacional, C.A.", Country: "Venezuela", IATA: "V4" },
  { Name: "VI Airlink", Country: "British Virgin Islands", IATA: "V6" },
  { Name: "ViaAir", Country: "United States", IATA: "VC" },
  { Name: "Vieques Air Link", Country: "Puerto Rico", IATA: "V4" },
  { Name: "VietJet Air", Country: "Vietnam", IATA: "VJ" },
  { Name: "Vietnam Air Services", Country: "Vietnam", IATA: "0V" },
  { Name: "Vietnam Airlines", Country: "Vietnam", IATA: "VN" },
  { Name: "Virgin Atlantic Airways", Country: "United Kingdom", IATA: "VS" },
  { Name: "Virgin Australia", Country: "Australia", IATA: "VA" },
  { Name: "Vision Airlines", Country: "United States", IATA: "V2" },
  { Name: "Vistara", Country: "India", IATA: "UK" },
  { Name: "Viva Air Colombia", Country: "Colombia", IATA: "FC" },
  { Name: "Viva Air Peru", Country: "Peru", IATA: "VV" },
  { Name: "VivaAerob�s", Country: "Mexico", IATA: "VB" },
  { Name: "Vladivostok Air", Country: "Russian Federation", IATA: "XF" },
  { Name: "VLM Airlines", Country: "Belgium", IATA: "VO" },
  { Name: "VLM Airlines Slovenia", Country: "Slovenia", IATA: "VO" },
  { Name: "Volaris", Country: "Mexico", IATA: "Y4" },
  { Name: "Volaris Costa Rica", Country: "Costa Rica", IATA: "Q6" },
  { Name: "Volga-Dnepr Airlines", Country: "Russian Federation", IATA: "VI" },
  { Name: "Volotea Airlines", Country: "Spain", IATA: "V7" },
  { Name: "Vueling Airlines", Country: "Spain", IATA: "VY" },
  { Name: "Wamos Air", Country: "Spain", IATA: "EB" },
  {
    Name: "Warbelow's Air Ventures Inc.",
    Country: "United States",
    IATA: "4W",
  },
  { Name: "Wasaya Airways", Country: "Canada", IATA: "WT" },
  { Name: "Wataniya Airways", Country: "Kuwait", IATA: "Q9" },
  { Name: "WDL Aviation", Country: "Germany", IATA: "WH" },
  { Name: "Welcome Air", Country: "Austria", IATA: "2W" },
  { Name: "West Air", Country: "China", IATA: "PN" },
  { Name: "West Atlantic", Country: "United Kingdom", IATA: "PT" },
  { Name: "West Coast Air", Country: "Canada", IATA: "8O" },
  { Name: "Westair Benin", Country: "Benin", IATA: "WH" },
  { Name: "Western Air", Country: "Bahamas", IATA: "WT" },
  { Name: "WestJet Airlines", Country: "Canada", IATA: "WS" },
  { Name: "Westjet Encore", Country: "Canada", IATA: "WR" },
  { Name: "White", Country: "Portugal", IATA: "WI" },
  { Name: "Wider�e", Country: "Norway", IATA: "WF" },
  { Name: "Winair", Country: "Netherlands Antilles", IATA: "WM" },
  { Name: "Windrose Airlines", Country: "Ukraine", IATA: "7W" },
  { Name: "Wingo", Country: "Colombia", IATA: "P5" },
  { Name: "Wings Air", Country: "Indonesia", IATA: "IW" },
  { Name: "Wings of Lebanon", Country: "Lebanon", IATA: "W7" },
  { Name: "Wizz Air", Country: "Hungary", IATA: "W6" },
  { Name: "Wizz Air UK", Country: "United Kingdom", IATA: "W9" },
  { Name: "World Atlantic Airlines", Country: "United States", IATA: "K8" },
  { Name: "WOW air", Country: "Iceland", IATA: "WW" },
  { Name: "Wright Air Service", Country: "United States", IATA: "8V" },
  { Name: "Xiamen Airlines", Country: "China", IATA: "MF" },
  { Name: "XL Airways France", Country: "France", IATA: "SE" },
  { Name: "Xpress Air", Country: "Indonesia", IATA: "XN" },
  { Name: "Xtra Airways", Country: "United States", IATA: "XP" },
  { Name: "Yakutia Airlines", Country: "Russian Federation", IATA: "R3" },
  { Name: "Yamal Airlines", Country: "Russian Federation", IATA: "YC" },
  { Name: "Yanair", Country: "Ukraine", IATA: "YE" },
  { Name: "Yangon Airways", Country: "Myanmar", IATA: "YH" },
  { Name: "Yangtze River Express", Country: "China", IATA: "Y8" },
  { Name: "Yemenia", Country: "Yemen", IATA: "IY" },
  { Name: "Yeti Airlines", Country: "Nepal", IATA: "YT" },
  { Name: "YTO Cargo", Country: "China", IATA: "YG" },
  { Name: "Zagros Airlines", Country: "Iran", IATA: "Z4" },
  { Name: "ZanAir", Country: "Tanzania", IATA: "B4" },
  { Name: "Zimbabwe Airways", Country: "Zimbabwe", IATA: "8Z" },
  { Name: "Zoom Air", Country: "India", IATA: "ZO" },
];
