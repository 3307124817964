import React from 'react';

import iconArrowDown from '../../assets/icon-arrow-down.svg';

function Header() {
  return (
    <div id="header-banner">
      <h2>We are</h2>
      <h1>First Class Service</h1>
      <h4>in New York City</h4>
      <div className="downArrow bounce">
        <img src={iconArrowDown} alt="Arrow Down" />
      </div>
    </div>
  );
}

export default Header;
