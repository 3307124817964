import { collection, setDoc, doc, getDocs } from "firebase/firestore";
import db from "../../firebase";

const vehiclesDB = "vehicles-web";

export const actions = {
  GET_VEHICLES: "GET_VEHICLES",
};

export const addVehicle = (vehicle) => {
  const docRef = collection(db, vehiclesDB);
  return setDoc(doc(docRef, vehicle.id), vehicle);
};

export const getVehicles = () => async (dispatch) => {
  const docRef = collection(db, vehiclesDB);
  const data = await getDocs(docRef)
    .then((querySnapShot) => querySnapShot.docs)
    .then((vehicles) => vehicles.map((v) => v.data()));

  const sortedData = data.sort((a, b) => a.priority - b.priority);

  dispatch({
    type: actions.GET_VEHICLES,
    payload: sortedData,
  });
};

const vehiclesReducer = (state = [], action) => {
  switch (action.type) {
    case actions.GET_VEHICLES:
      return action.payload;
    default:
      return state;
  }
};

export default vehiclesReducer;
