import React from "react";

import HeaderSecondary from "../../components/HeaderSecondary";

function AboutUs() {
  return (
    <>
      <HeaderSecondary
        pageTitle="About Us"
        pageSubtitle="Luxury Transportation in NYC"
      />
      <section id="main-offers" className="section-white">
        <div className="container">
          Lorem ipsum dolor sit amet, consectetur adip
        </div>
      </section>
    </>
  );
}

export default AboutUs;
