import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { getVehicles } from "./redux/reducers/vehiclesReducer";

import Footer from "./components/Footer";
import Menu from "./components/Menu";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurFleet from "./pages/OurFleet";
import Reserve from "./pages/Reserve";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import "./App.scss";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVehicles());
  }, []);

  return (
    <>
      <Router>
        <Menu />
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/terms" component={Terms} />
          <Route path="/reserve" component={Reserve} />
          <Route path="/fleet" component={OurFleet} />
          <Route path="/about" component={AboutUs} />

          <Route path="/thanks" component={Home} />
          <Route path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
