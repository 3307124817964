const mapBookingToSQL = (bookingData) => ({
  name: bookingData.passenger || "",
  email: bookingData.email || "",
  phone: bookingData.phone_number || "",
  optphone: bookingData.phone_number_optional || "",
  passenger: bookingData.number_of_passenggers || "",
  luggage: bookingData.number_of_luggage || "",
  airline_name: bookingData.airline || "",
  flight_number: bookingData.flight_number || "",
  flying_from: bookingData.city_of_departure || "",
  verifyCode: "",
  meet: bookingData.meet_and_greet ? "Yes" : "No",
  comentario: bookingData.special_needs || "",
  tipopago: bookingData.payment_method || "creditcard",
  carro: bookingData.vehicle_type.name || "",
  promocode: bookingData.promo_code || "",
});

export default mapBookingToSQL;
