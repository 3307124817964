/* eslint-disable no-debugger */
import axios from "axios";
import Swal from "sweetalert2";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

import db from "../../firebase";
import mapBookingToSQL from "../../helpers/mapBookingToSQL";

const bookingPreloadedState = {
  pickup_address: "",
  dropoff_address: "",
  pickup_date: "",
  pickup_time: "",
  number_of_passenggers: "",
  vehicle_type: "",

  service_type: "",
  service_hours: "",
  round_trip: "",

  passenger: "",
  email: "",
  phone_number: "",
  phone_number_optional: "",
  pet_transportation: "",
  car_seat: "",
  special_needs: "",

  airline: "",
  flight_number: "",
  city_of_departure: "",
  number_of_luggage: "",
  meet_and_greet: "",

  payment_method: "",
  cardholder: {
    address: "",
    suite: "",
    city: "",
    state: "",
  },
  creditcard: {
    name: "",
    number: "",
    expiration_date: "",
    cvv: "",
    zipcode: "",
  },

  promo_code: "",

  provider: "Privadas",
};

const bookingsDB = "bookings-test";
const statsDoc = "--stats--";

export const actions = {
  SET_BOOKING: "SET_BOOKING",
  CLEAR_BOOKING: "CLEAR_BOOKING",
  SAVE_BOOKING_FB: "SAVE_BOOKING_FB",
  SAVE_BOOKING_SQL: "SAVE_BOOKING_SQL",
  SET_BOOKING_PROPERTY: "SET_BOOKING_PROPERTY",
};

export const updateBookingCounter = async () => {
  const docRef = doc(db, bookingsDB, statsDoc);
  const docSnap = await getDoc(docRef);
  const data = await docSnap.data();

  await setDoc(
    docRef,
    { bookingCounter: data.bookingCounter + 1 },
    { merge: true }
  );

  return data.bookingCounter + 1;
};

export const setBooking = (data) => (dispatch, getState) => {
  const { booking } = getState();
  const newBooking = { ...booking, ...data };

  dispatch({
    type: actions.SET_BOOKING,
    payload: newBooking,
  });
};

export const setBookingProperty = (property, value) => (dispatch, getState) => {
  const { booking } = getState();
  const newBooking = { ...booking, [property]: value };

  dispatch({ type: actions.SET_BOOKING, payload: newBooking });
};

export const saveBooking = async (booking) => {
  const docRef = collection(db, bookingsDB);
  const currentCounter = await updateBookingCounter();
  const bookingId = currentCounter.toString();

  return setDoc(doc(docRef, bookingId), {
    _id: bookingId,
    ...booking,
  });
};

export const saveBookingSQL = async (booking) => {
  try {
    const currentBooking = mapBookingToSQL(booking);
    // const apiURL = `${process.env.REACT_APP_API_URL}/reservations`;

    const { data } = await axios.post(
      "https://backend.bestlimo1.com/api/reservations",
      {
        ...currentBooking,
        rawData: booking,
      }
    );

    if (data.status === 200) {
      Swal.fire({
        title: "Thanks!",
        text: "We will contact you soon",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#c7081b",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Oops!",
      text: "Something went wrong... Please try again later",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#c7081b",
      footer: `<span>${error}</span>`,
    });
  }
};

export const clearBooking = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_BOOKING });
};

const bookingReducer = (state = bookingPreloadedState, action) => {
  switch (action.type) {
    case actions.SET_BOOKING:
    case actions.SET_BOOKING_PROPERTY:
      return action.payload;

    case actions.CLEAR_BOOKING:
      return bookingPreloadedState;

    default:
      return state;
  }
};

export default bookingReducer;
