/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const GoogleAddressInput = ({ address, setAddress, addressType }) => {
  const [coordinates, setCoordinates] = useState({});

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleSelect = async (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setCoordinates(latLng))
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="form-floating">
          <>
            <input
              {...getInputProps({
                id: `floating${addressType}`,
                placeholder:
                  addressType === "PICKUP"
                    ? "Pickup Address"
                    : "Dropoff Address",
                className: "form-control location-search-input",
              })}
            />
            <label
              htmlFor={
                addressType === "PICKUP" ? "floatingPICKUP" : "floatingDROPOFF"
              }
            >
              {addressType === "PICKUP" ? "Pickup Address" : "Dropoff Address"}
            </label>

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      borderBottom: "1px solid #e9ecef",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                      padding: "5px 10px",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      borderBottom: "1px solid #e9ecef",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                      padding: "5px 10px",
                    };
                return (
                  <div
                    key={suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleAddressInput;
