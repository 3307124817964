import React from "react";

import Header from "../../components/Header";
import Offers from "../../components/Offers";
import Booking from "../../components/Booking";
import Statistics from "../../components/Statistics";
import Reviews from "../../components/Reviews";
import Promo from "../../components/Promo";

function Home() {
  return (
    <>
      <Header />
      <Offers />
      <Booking />
      <Statistics />
      <Reviews />
      <Promo />
    </>
  );
}

export default Home;
