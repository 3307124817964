/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MainForm from "./MainForm";
import TravelForm from "./TravelForm";
import AirlineForm from "./AirlineForm";
import PaymentForm from "./PaymentForm";
import PersonalForm from "./PersonalForm";
import Stepper from "./Stepper";

function Booking() {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  return (
    <>
      {step >= 1 && <Stepper step={step} />}
      {step === 0 && <MainForm step={step} setStep={setStep} />}
      {step === 1 && <TravelForm step={step} setStep={setStep} />}
      {step === 2 && <PersonalForm step={step} setStep={setStep} />}
      {step === 3 && <AirlineForm step={step} setStep={setStep} />}
      {step === 4 && <PaymentForm step={step} setStep={setStep} />}
    </>
  );
}

export default Booking;
