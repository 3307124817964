/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
import React from "react";

const Stepper = ({ step }) => (
  <section id="stepper-section" className="section-light no-padding">
    <div
      className="container"
      style={{
        zIndex: "99",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="container progress"
        style={{
          position: "relative",
          width: "100%",
          height: "8px",
          zIndex: "1",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${(step - 1) * 33}%`,
            position: "relative",
            marginTop: "-20px",
            top: "20px",
            height: "8px",
            lineHeight: "8px",
            zIndex: "100",
          }}
        />
      </div>
      <div
        className="container"
        style={{ position: "relative", top: "-24px", zIndex: "100" }}
      >
        <span className={step === 1 ? "step-icon active" : "step-icon"}>1</span>
        <span className={step === 2 ? "step-icon active" : "step-icon"}>2</span>
        <span className={step === 3 ? "step-icon active" : "step-icon"}>3</span>
        <span className={step === 4 ? "step-icon active" : "step-icon"}>4</span>
      </div>
    </div>
  </section>
);

export default Stepper;
