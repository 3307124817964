export const roundTripDiscount = 10;

export const serviceTypes = {
  PICKUP_DROPOFF: "PICKUP_DROPOFF",
  HOURS: "HOURS",
};

export const paymentMethods = {
  CASH: "CASH",
  CREDIT_CARD: "CREDIT_CARD",
};

export const hours = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
