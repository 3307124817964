import React from 'react';

function Reviews() {
  return (
    <section id="reviews" className="section-white">
      <div className="container">
        <h2>Our Customers Say!</h2>
        <h5>Hundreds of reviews in Yelp, Google and other platforms...</h5>

        <div className="elfsight-app-5ca8c6d1-e616-45af-87d3-4f84ac0011ac" />
      </div>
    </section>
  );
}

export default Reviews;
