import { combineReducers } from "redux";

import booking from "./bookingReducer";
import vehicles from "./vehiclesReducer";

const rootReducer = combineReducers({
  vehicles,
  booking,
});

export default rootReducer;
